import { Component, OnInit, NgZone, HostListener, Input, Output, EventEmitter} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, mergeMap, timer } from 'rxjs';
import { APICallsService } from 'src/app/core/services/APICalls.service';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { environment } from 'src/environments/environment';
declare const Persona: any;
// import { Client, Inquiry } from 'persona'

@Component({
    selector: 'app-renewal-verify-user',
    templateUrl: './renewal-verify-user.component.html',
    styleUrls: ['./renewal-verify-user.component.scss']
})
export class RenewalVerifyUserComponent implements OnInit{

    @Input('verifier') verifier:any;
    @Input('id') id:any;
    @Input('subscriptionId') subscriptionId:any;
    @Input('transactionId') transactionId:any
    @Output() triggerGoBackToSec = new EventEmitter<any>();
    // @Output() triggerOngoingVerification = new EventEmitter<boolean>();

    // step = -1
    prevent = false
    timedSubscribedable = new Subscription()

    transactionCheck:any = {
        // transactionAllowed: false,
        // hasAvaliableVerificationSlot: false,
        internalError:false,
        // validDateId:false
    }

    personaLoader: boolean = true;

    constructor(
      // private route: ActivatedRoute,
      private fS: FirebaseService,
      private router: Router,
      private ngZone: NgZone,
      // private http: HttpClient,
      private apiS: APICallsService,
    //   public client: Client,
      ) 
    { }
  
    ngOnInit(): void {
        // this.createTimer()
    }

    // createTimer(){
    //     timer(0,10000).subscribe(()=>{
    //         this.step++
    //         if(this.step > 4) this.step = 0
    //     })
    // }

    verifyPersona(){
      this.personaLoader = false
      this.resetVerifyPersonaErrors()
      if(!environment.skip.verification.allowedOnWeekendCheck){
          // console.log('getCheckTransactionAllowedUrl: ',this.apiS.getCheckTransactionAllowedUrl)
          this.apiS.apiGet(this.apiS.getCheckTransactionAllowedUrl)
          .subscribe({
              next:(apiResult)=>{
                  // console.log('apiResult: ',apiResult)
                  if(apiResult){
                      if(apiResult.success === true && apiResult.verifier){
                          const verifier = apiResult.verifier
                          // console.log('verifier: ',verifier)
                          // if(verifier.result.hasAvaliableVerificationSlot === true) this.transactionCheck.hasAvaliableVerificationSlot = verifier.result.hasAvaliableVerificationSlot
                          // if(verifier.result.transactionAllowed === true) this.transactionCheck.transactionAllowed = verifier.result.transactionAllowed
                          // if(verifier.result.validDateId === false) this.transactionCheck.internalError = true

                          // check if transaction check is good then go to verify self
                          let proceedToVerification:boolean = true;
                          Object.keys(this.transactionCheck).some((val)=>{
                              // console.log(val+'val: ',this.transactionCheck[val])
                              if(val == 'internalError'){
                                  if(this.transactionCheck[val] === false){
                                      return false
                                  }else{
                                      proceedToVerification = false;
                                      return true
                                  }
                              }
                              else{
                                  if(this.transactionCheck[val] === true){
                                      return false
                                  }else{
                                      proceedToVerification = false;
                                      return true
                                  }
                              }
                          })
                          
                          // console.log('transactionCheck: ',this.transactionCheck)
                          // console.log('proceedToVerification: ',proceedToVerification)
                          if(proceedToVerification == true) this.verifyPersona2(this.verifier.email)
                      }else{
                          // either not success or no data
                          this.transactionCheck.internalError = true
                          this.personaLoader = true
                      }
                  }else{
                      // successfully recieved data from persona but null data
                      this.transactionCheck.internalError = true
                      this.personaLoader = true
                  }
              },
              error:(err)=>{
                  // console.log('Error: ',err)
                  // console.error('Error')
                  this.transactionCheck.internalError = true
                  this.personaLoader = true
              }
          })
      }
      else{
        this.personaLoader = true
      }
      // else {
      //   // theres no generated documents for weekends
      //   // check if theres a document for this weekend
      //   // if none create document then use newly created document
      //   // if exist use document for today
      //   // get date
      //   this.verifyPersona2()
      // }
    }

    async verifyPersona2(email:string){
        // console.log({email})
        const verificationReservedId = await this.fS.reserveId(environment.paymentTC).id
        // console.log('verificationReservedId: ',verificationReservedId)
        // console.log('opening')
        const client = new Persona.Client({
            templateId: this.verifier.personaTemplateId,
            referenceId: verificationReservedId,
            environment: "production",
            fields: {
                emailAddress: email,
            },
            onReady:()=>{
                client.open()
                this.personaLoader = false
            },
            onLoad:(()=>{}),
            onComplete:({inquiryId, status, fields}:any)=>{
                // this.personaLoader = true
                const data = {
                    inquiryId: inquiryId,
                    status: status,
                    fields: fields,
                    personaTemplateId: this.verifier.personaTemplateId,
                    id:this.id,
                    verificationId: verificationReservedId,
                    subscriptionId: this.subscriptionId,
                    transactionId: this.transactionId
                }
                // console.log('persona data: ',data)
                // this.triggerOngoingVerification.emit(false)

                this.apiS.apiPost(this.apiS.getRenewalUploadKYCData,data)
                .subscribe({
                    next:(apiResult)=>{
                        // this.personaLoader = true
                        console.log('apiResult: ',apiResult)
                            if(apiResult?.kycData?.successfulVerification === true)
                                this.ngZone.run(() => { this.router.navigate(['/verification-success'])}); // ,this.currentVerifier.id
                            else this.ngZone.run(() => { this.router.navigate(['/failed-renewal-verification'])});
                    },
                    error:(err)=>{
                        this.personaLoader = true
                        // console.error('Error: ',err)
                        // console.error('Error')
                        this.ngZone.run(() => { this.router.navigate(['/page-error'])});
                    }
                })

                // this.triggerOngoingVerification.emit(false)
                // this.personaLoader = true
            },
            onCancel:({inquiryId, sessionToken}:any)=>{
                // console.warn("persona cancel")
                // console.warn("Cancel")
                // this.triggerOngoingVerification.emit(false)
                this.personaLoader = true
                // this.ngZone.run(() => { this.router.navigate(['/verification-failed'])});//,this.id
            },
            onError:(error:any) =>{
                // console.error("persona error: ",error)
                // console.error("Error")
                // this.triggerOngoingVerification.emit(false)
                const data = {
                    inquiryId: null,
                    status: null,
                    fields: null,
                    personaTemplateId: this.verifier.personaTemplateId,
                    id:this.id,
                    verificationId: verificationReservedId,
                    subscriptionId:this.subscriptionId,
                    transactionId: this.transactionId,
                    error:error
                }
                this.apiS.apiPost(this.apiS.getRenewalUploadKYCData,data)
                this.personaLoader = true
                // this.ngZone.run(() => { this.router.navigate(['/verification-failed'])});//,this.id
            }
        })
    }

    resetVerifyPersonaErrors(){
        Object.keys(this.transactionCheck).map(val=>{
            // console.log('resetVerifyPersonaErrors: ',val)
            if(val == 'internalError') this.transactionCheck[val] = false
            else this.transactionCheck[val] = false
        })
    }

    goBackToSec(){
        this.triggerGoBackToSec.emit()
    }

    setStep(index:number){
        // this.step = index
    }
}