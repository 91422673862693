import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class DisableBackGuard implements CanDeactivate<ComponentCanDeactivate>{
    canDeactivate(component: ComponentCanDeactivate, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot):boolean{
        // console.log('canDeactivate: ',component.canDeactivate())
        // console.log('currentRoute: ',currentRoute)
        // console.log('currentState: ',currentState)
        // console.log('nextState: ',nextState)
        if(!component.canDeactivate()){
            // check if next route is previous route
            // console.log({history})
            return true//false
        }else return true
        // return component.canDeactivate() ? true : false
        // return true
    }
}