import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class APICallsService{
    private secServer = {
        checkVerifierStatusUrl: environment.secServerBaseUrl+'/server/v1'+environment.environmentURLCode+'/checkVerifierStatus',
        fetchVerifierDataUrl: environment.secServerBaseUrl+'/server/v1'+environment.environmentURLCode+'/fetchVerifierData',
        createGoodPayTransactionUrl: environment.secServerBaseUrl+'/server/v1'+environment.environmentURLCode+'/createGoodPayTransaction',
        checkVerificationAllowedToday: environment.secServerBaseUrl+'/server/v1'+'/checkTransactionAllowedToday',
        increaseTodayReserveCounter: environment.secServerBaseUrl+'/server/v1'+'/increaseTodayReserveCounter',
        decreaseDateReserveCounter: environment.secServerBaseUrl+'/server/v1'+'/decreaseDateReserveCounter',
        uploadKYCData: environment.secServerWithIPBaseUrl+'/server/v1'+environment.environmentURLCode+'/uploadKYCData',
        fetchVerificationCountAccess: environment.secServerBaseUrl+'/server/v1'+environment.environmentURLCode+'/fetchVerificationCountAccess'
    }
    private checkTransactionAllowedUrl = environment.secServerBaseUrl+'/server/v1'+'/checkTransactionAllowedToday'
    private fetchDateTodayUrl = environment.secServerBaseUrl+'/secVerificationTransaction/api/v1/fetchDateToday'
    private proxyCreateAPIUrl = environment.proxyCreateAPIUrl
    private proxyFetchAPIUrl = environment.proxyFetchAPIUrl

    constructor(
        private http: HttpClient,
    ){}

    apiPost(url:string,data:any){
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post<any>(url,data,httpOptions)
    }

    apiGet(url:string){
        return this.http.get<any>(url)
    }

    get getUploadKYCData(){
        return this.secServer.uploadKYCData
    }

    get getIncreaseTodayReserveCounterURL(){
        return this.secServer.increaseTodayReserveCounter
    }

    get getDecreaseDateReserveCounterURL(){
        return this.secServer.decreaseDateReserveCounter
    }

    get getCheckVerificationAllowedTodayUrl(){
        return this.secServer.checkVerificationAllowedToday
    }

    get getCreateGoodPayTransactionUrl(){
        return this.secServer.createGoodPayTransactionUrl
    }

    get getVerifierStatusUrl(){
        return this.secServer.checkVerifierStatusUrl
    }

    get getVerifierDataUrl(){
        return this.secServer.fetchVerifierDataUrl
    }

    get getFetchVerificationCountAccessUrl(){
        return this.secServer.fetchVerificationCountAccess
    }

    get getCheckTransactionAllowedUrl(){
        return this.checkTransactionAllowedUrl
    }

    get getProxyCreateAPIUrl(){
        return this.proxyCreateAPIUrl
    }

    get getProxyFetchAPIUrl(){
        return this.proxyFetchAPIUrl
    }

    get getFetchDateTodayUrl(){
        return this.fetchDateTodayUrl
    }
}