<section>
    <div class="container d-flex flex-column justify-content-center align-items-center" style="height: 100vh;">
        <!-- <div class="d-flex justify-content-center align-items-center flex-column"> -->
            <div class="d-flex flex-row  justify-content-center align-items-center flex-wrap">
                <img class="icon-dimension exclamation-icon" src="assets/icons/warning.svg">
                <span class="text">Restricted Access</span>
            </div>
        <!-- </div> -->
        <div class="error-msgs d-flex align-items-left flex-column" *ngIf="hasErrors && errorsData.length > 0">
            <p style="margin-left: 15%;">Error/s :</p>
            <ul style="margin-left: 15%;">
                <ng-container *ngFor="let error of errorsData">
                    <li style="text-indent: 2em;">
                        <p >{{error}}</p>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
</section>