<section>
  <div class="container" style="height: 100vh">
      <div style="height: 100vh;" class="d-flex justify-content-center align-items-center">
          <!-- style="width: 50em;background-color: rgb(216, 105, 90); padding:2em; color:whitesmoke" -->
          <div class="form-group card shadow notice-card" >
              <div class="notice-header notice-header-warning">
                  <h2 class="d-flex align-items-center fw-bold">
                      Access denied!
                  </h2>
              </div>
              Access to Early Subscription is denied.
          </div>
      </div>
  </div>
</section>