<!-- <div class="container" style="height: 100vh;">
    <div style="height: 100vh;" class="d-flex justify-content-center align-items-center">
        
        <div class="form-group card shadow" style="width: 40em;background-color: rgb(90, 216, 164); padding:2em;">
                                                                    goodpay logo
            <div class="row">
                <div class="col">
                    <div class="goodpay_image">
                        <div class="img-wrapper">
                            <img src="assets/images/goodpay/goodpay_logo.jpg">
                        </div>
                    </div>
                </div>
            </div>

                                                                    amount
            <div class="row">
                <div class="col-3 fw-bold d-flex align-items-center">Amount</div>
                <div class="col-1 fw-bold d-flex align-items-center">:</div>
                <div class="col d-flex align-items-center"><input class="form-control" readonly disabled type="text" placeholder="" [value]="verifier.valueToBePaid"></div>
            </div>

                                                                    first name
            <div class="row">
                <div class="col-3 fw-bold d-flex align-items-center">First name</div>
                <div class="col-1 fw-bold d-flex align-items-center">:</div>
                <div class="col d-flex align-items-center"><input class="form-control" readonly disabled type="text" [ngModel]="verifier.fname" placeholder=""></div> 
            </div>

                                                                    last name
            <div class="row">
                <div class="col-3 fw-bold d-flex align-items-center">Last name</div>
                <div class="col-1 fw-bold d-flex align-items-center">:</div>
                <div class="col d-flex align-items-center"><input class="form-control" readonly disabled type="text" [ngModel]="verifier.lname" placeholder=""></div> 
            </div>
            
                                                                    phone number
            <form class="row" [formGroup]="createTransactionForm">
                <div class="col-3 fw-bold d-flex align-items-center">
                        Mobile number</div>
                <div class="col-1 fw-bold d-flex align-items-center">:</div>
                <div class="col d-flex align-items-center">
                    <input class="form-control static-input-width" readonly disabled type="text" value="+63"><input class="form-control" id="contactNo" name="contactNo" appPhoneMask maxlength="12" type="text" formControlName="contactNo" placeholder="Ex. 912-345-678">
                </div>
                <ng-container>
                    <span style="color:rgb(45, 108, 233)" *ngIf="!isTouched('contactNo')">Input Optional</span>
                </ng-container>
                <ng-container *ngFor="let item of createTransactionFormCheck.contactNo">
                    <span style="color:rgb(201, 38, 38);font-weight: bold;" *ngIf="hasError('contactNo',item.type)">* {{item.message}}</span>
                </ng-container>
            </form>

                                                                    email
            <div class="row">
                <div class="col-3 fw-bold d-flex align-items-center">Email</div>
                <div class="col-1 fw-bold d-flex align-items-center">:</div>
                <div class="col d-flex align-items-center"><input class="form-control" readonly disabled type="text" [ngModel]="verifier.email" placeholder=""></div> 
            </div>

            <div class="row">
                <div class="col flex justify-content-center">
                    <button type="button" (click)="createTransaction()" [disabled]="proceedToGoodPayLoading" class="btn btn-primary w-100">
                        <span [hidden]="!proceedToGoodPayLoading">
                            <img style="margin-right: 1em; width: 2rem; height: 2rem;" src="assets/loaders/loading.svg">
                        </span>
                        <span>Proceed to GoodPay</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- class="d-flex justify-content-center align-items-center" -->
<section class="page-body">
    <section class="show-body">
        <div class="goodpay_image">
            <div class="img-wrapper">
                <img src="assets/images/goodpay/goodpay_logo.jpg">
            </div>
        </div>
        <!-- class="d-flex justify-content-center align-items-center" -->
        <div style="margin-top: 2em;">
            <!-- amount -->
            <!-- <div class="row row-width">
                <div class="col-3 fw-bold d-flex align-items-center">Amount</div>
                <div class="col-1 fw-bold d-flex align-items-center">:</div>
                <div class="col d-flex align-items-center"><input class="form-control" readonly disabled type="text" placeholder="" [value]="verifier.valueToBePaid"></div>
            </div> -->
            <div class="container">
                <div class="row">
                    <div class="col d-flex align-items-center flex-wrap">
                        <span class="fw-bold" >Amount</span>
                        <input class="form-control" readonly disabled type="text" placeholder="" [value]="verifier.valueToBePaid">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg" style="margin-top: 1em;">
                        <span class="fw-bold">First name</span>
                        <input class="form-control" readonly disabled type="text" [ngModel]="verifier.fname" placeholder="">
                    </div>
                    <div class="col-12 col-lg" style="margin-top: 1em;">
                        <span class="fw-bold">Last name</span>
                        <input class="form-control" readonly disabled type="text" [ngModel]="verifier.lname" placeholder="">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg" style="margin-top: 1em;" [formGroup]="createTransactionForm">
                        <span class="fw-bold">Mobile number</span>
                        <div class="d-flex align-items-center">
                            <input class="form-control static-input-width" readonly disabled type="text" value="+63">
                            <input class="form-control" id="contactNo" name="contactNo" appPhoneMask maxlength="12" type="text" formControlName="contactNo" placeholder="Ex. 912-345-678">
                        </div>
                        <ng-container>
                            <span style="color:rgb(45, 108, 233)" class="fw-bold" *ngIf="!isTouched('contactNo')">Input Optional</span>
                        </ng-container>
                        <ng-container *ngFor="let item of createTransactionFormCheck.contactNo">
                            <span style="color:rgb(201, 38, 38); font-weight: bold;" *ngIf="hasError('contactNo',item.type)">* {{item.message}}</span>
                        </ng-container>
                    </div>
                    <div class="col-12 col-lg" style="margin-top: 1em;">
                        <span class="fw-bold">Email</span>
                        <div class="d-flex align-items-center"><input class="form-control" readonly disabled type="text" [ngModel]="verifier.email" placeholder=""></div> 
                    </div>
                </div>
            </div>
        </div>
        
        <div class="container d-flex justify-content-center" style="margin-top: 2em;">
            <button type="button" (click)="createTransaction()" [disabled]="proceedToGoodPayLoading" class="btn btn-primary w-100">
                <span [hidden]="!proceedToGoodPayLoading">
                    <img style="margin-right: 1em; width: 2rem; height: 2rem;" src="assets/loaders/loading.svg">
                </span>
                <span>Proceed to Payment</span>
            </button>
        </div>
    </section>
</section>
