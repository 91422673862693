import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-under-maintenance',
  templateUrl: './under-maintenance.component.html',
  styleUrls: ['./under-maintenance.component.scss']
})
export class UnderMaintenanceComponent implements OnInit{
  constructor(){

  }

  ngOnInit(): void{ }

  goBack(){
    window.location.replace(environment.secureCallback);
  }
}