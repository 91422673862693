import { Injectable } from '@angular/core';
import { 
    Firestore,
    where,
    collectionSnapshots,
    query,
    collection,
    doc,
    docData,
    docSnapshots,
    updateDoc,
    addDoc,
    setDoc,
    // serverTimestamp,
    increment,
} from '@angular/fire/firestore';

// import { FirebaseApp } from '@angular/fire/app';

// import { ref, getDatabase, onValue } from '@angular/fire/database';

import firebase from 'firebase/compat/app';
// import Timestamp = firebase.firestore.Timestamp;

import { environment } from 'src/environments/environment';
import { APICallsService } from './APICalls.service';

// import { ref } from "firebase/database"

@Injectable({
    providedIn: 'root',
})
export class FirebaseService {

    

    constructor(
        private fireS:Firestore,
        // private fireA:FirebaseApp,
        // private apiS: APICallsService,
    ){}

    getCollection(collectionName:any){
        const refCol = collection(this.fireS, collectionName)
        return  collectionSnapshots(refCol)
    }

    getCollectionWhere(collectionData:any,fieldName:any,operator:any,value:any){
        const refCol = collection(this.fireS, collectionData);
        const q = query(refCol, where(fieldName,operator,value))
        return collectionSnapshots(q) 
    }

    getDocByRef(collectionRef:any){
        const refCol = doc(this.fireS,collectionRef)
        return docData(refCol)
    }

    getDocByRefSnapshot(collectionRef:any){
        const refCol = doc(this.fireS,collectionRef)
        return docSnapshots(refCol)
    }

    updateKYC(collectionRef:any,data:any){
        // let time = 
        const refCol = doc(this.fireS,collectionRef)
        return updateDoc(refCol,{
            kyc_data: data
        }) //not mini update?
    }

    addData(collectionName:any,data:any){
        data.date_created = firebase.firestore.Timestamp.now()
        const refCol = collection(this.fireS,collectionName)
        return addDoc(refCol,data)
    }

    setData(collectionName:any,id:any,data:any){
        data.date_created = firebase.firestore.Timestamp.now()
        return setDoc(doc(this.fireS,collectionName+"/"+id),data)
    }

    updatePaymentTransactionRefColID(collectionRef:any,id:any){
        const refCol = doc(this.fireS,collectionRef)
        return updateDoc(refCol,{
            "transaction_data.refColerence_id": id,
        })
    }

    updatePaymentTransactionGoodPayResponse(collectionRef:any,data:any){
        const refCol = doc(this.fireS,collectionRef)
        return updateDoc(refCol,{
            "goodpay_response.gp_refColerence": data.gp_refColerence,
            "goodpay_response.link": data.link,
            "goodpay_response.returned_at": data.returned_at,
        })
    }

    updateVerifierCollectionPayment(collectionRef:any,data:any){
        const refCol = doc(this.fireS,collectionRef)
        return updateDoc(refCol,{
            "payment_data.status": data.status,
            "payment_data.success": data.success,
            "payment_data.subscription_end_date": data.subscription_end_date,
        })
    }

    updatePaymentTransactionPayment(collectionRef:any,data:any){
        const refCol = doc(this.fireS,collectionRef)
        return updateDoc(refCol,{
            paid: data.paid,
            payment_verified_date: data.payment_verified_date,
            amount_paid:data.amount_paid,
        })
    }

    updateOnlyVerifierSubscriptionEndDate(collectionRef:any, data:any){
        const refCol = doc(this.fireS,collectionRef)
        return updateDoc(refCol,{
            "payment_data.subscription_end_date": data.subscription_end_date,
        })
    }

    updatePaymentDateEnd(collectionRef:any, data:any){
        const refCol = doc(this.fireS,collectionRef)
        return updateDoc(refCol,{
            subscription_end_date: data.subscription_end_date,
        })
    }

    updateInsufficientPaymentTransactionPayment(collectionRef:any,ammount_paid:any){
        const refCol = doc(this.fireS,collectionRef)
        return updateDoc(refCol,{
            "transaction_data.amount_paid":ammount_paid,
        })
    }

    createVerificationCollectionPaymentData(collectionRef:any,data:any){
        const refCol = doc(this.fireS,collectionRef)
        return updateDoc(refCol,{
            payment_data:data
        })
    }

    updateOldVerficationData(collectionRef:any,data:any){
        const refCol = doc(this.fireS,collectionRef)
        return updateDoc(refCol,{
            fname: data.fname,
            mname: data.mname,
            lname: data.lname,
            bd: data.bd
        })
    }

    reserveId(collectionName:any){
       return doc(collection(this.fireS,collectionName))
    }

    dailyCounterIncrease(formattedDate:string){
        // current day

        return updateDoc(doc(this.fireS,environment.dailyTC+'/'+formattedDate),{
            counter: increment(1),
            reserve_counter: increment(-1),
        })
    }

    dailyReserveCounterDecrease(formattedDate:string){
        // return updateDoc(doc(this.fireS,environment.dailyTC),{
        //     reserve_counter: increment(-1),
        // })

        return updateDoc(doc(this.fireS,environment.dailyTC+'/'+formattedDate),{
            reserve_counter: increment(-1),
        })
    }

    dailyReserveCounterIncrease(formattedDate:string){
        // return updateDoc(doc(this.fireS,environment.dailyTC),{
        //     reserve_counter: increment(1),
        // })

        return updateDoc(doc(this.fireS,environment.dailyTC+'/'+formattedDate),{
            reserve_counter: increment(1),
        })
    }
}