import { Component, OnInit, NgZone, HostListener, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APICallsService } from 'src/app/core/services/APICalls.service';

@Component({
  selector: 'app-verifying-renewal-payment-transaction.component',
  templateUrl: './verifying-renewal-payment-transaction.component.html',
  styleUrls: ['./verifying-renewal-payment-transaction.component.scss']
})
export class VerifyingRenewalPaymentTransactionComponent implements OnInit{
  loader: boolean = true;
  token:any = this.route.snapshot.params['tokenId']
  subId:any = this.route.snapshot.params['subId']
  transactionId:any = this.route.snapshot.params['transactionId']

  status:any = null

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    // public dialog: MatDialog,
    private apiS: APICallsService,
  ) 
  { }

  ngOnInit(): void{
    console.log('start prog')
    // this.token = sessionStorage.getItem('token')
    console.log('token: ',this.token)
    console.log('subId: ',this.subId)
    if(this.token != '' && this.token != null && this.token != undefined) this.checkingVerifer()
    else this.router.navigate(['/restricted-access'])
  }

  checkingVerifer(){
    let data = {
      token: this.token,
      transactionId: this.transactionId
    }
    this.apiS.apiPost(this.apiS.getVerifierStatusUrl,data)
    .subscribe({
      next:(result)=>{
        console.log('checkingVerifer: ',result)
        if(result?.errors){
          const errors = result.errors
          this.router.navigate(['/restricted-access'],{ queryParams: {errors} })
        }
        else if(result?.success){
          const response = result.data
          if(response?.continue){
            sessionStorage.setItem('id', response.id)
            sessionStorage.setItem('token', this.token)
            sessionStorage.setItem('subId', this.subId)
            sessionStorage.setItem('transactionId', this.transactionId)
            // this.router.navigate(['/persona-verification'])
            this.checkPaymentTransaction()
          }
          else this.router.navigate(['/restricted-access'])
        }
        else this.router.navigate(['/restricted-access'])
      },
      error:(err)=>{
         //an error occured in cFunc
         console.error('Error: ',err)
        //  console.error('Error')
        this.router.navigate(['/restricted-access'])
      }
    })
  }

  checkPaymentTransaction(){
    console.log('start checkPaymentTransaction')
    this.loader = false
    try{ 
      let data = {
        id:sessionStorage.getItem('id'),
        subId:sessionStorage.getItem('subId'),
        transactionId: this.transactionId
      }
      let unsub = this.apiS.apiPost(this.apiS.getCheckRenewalPaymentTransactionUrl,data)
      .subscribe({
        next:(result:any)=>{
          console.log('checkPaymentTransaction: ',result)
          if(result?.errors){
            const errors = result.errors
            unsub.unsubscribe()
            this.router.navigate(['/restricted-access'],{ queryParams: {errors} })
          }
          else if(result?.success == true){
            const verifier = result?.data?.verifier
            if(result?.data?.result){
              const resultData = result?.data?.result
              if(resultData.paid === true){
                unsub.unsubscribe()
                this.status = 'paid'
              }

              else if(resultData.forLoading === true){
                this.status = 'forLoading'
                unsub.unsubscribe()
                setTimeout(()=>this.checkPaymentTransaction(),5000)
              }

              else if(resultData.noCurrentSub === true){
                unsub.unsubscribe()
                this.status = 'noCurrentSub'

              }

              else if(resultData.paymentFailed === true){
                unsub.unsubscribe()
                this.status = 'paymentFailed'
              }

              else if(resultData.contactHelp === true){
                unsub.unsubscribe()
                this.ngZone.run(()=>this.router.navigate(['/page-error']))
              }

              else if(resultData.siteUnderMaintenance){
                unsub.unsubscribe()
                this.ngZone.run(()=>this.router.navigate(['/under-maintenance']))
              }

              else{
                console.log('rechecking payment')
                let errors = ['Internal error']
                this.ngZone.run(() => {this.router.navigate(['/restricted-access'],
                {queryParams:{errors}})});
                unsub.unsubscribe()
              }
            }
            else {
              unsub.unsubscribe()
              this.ngZone.run(()=>this.router.navigate(['/page-error']))
            }
          }
          else {
            this.ngZone.run(()=>this.router.navigate(['/restricted-access']))
            unsub.unsubscribe()
          }
        },
        error:(err)=>{
          console.error(err)
          unsub.unsubscribe()
          let errors = ['Internal error']
          this.ngZone.run(() => {this.router.navigate(['/restricted-access'],
          {queryParams:{errors}})});
        }
      })
    }
    catch(err){
      console.error(err)
      let errors = ['Internal error']
      this.ngZone.run(() => {this.router.navigate(['/restricted-access'],
      {queryParams:{errors}})});
    }
  }

  continueProcess(){
    if(this.status == 'paid'){
      this.router.navigate(['/renewal',this.token,this.transactionId])
    }
  }
}
