import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { APICallsService } from 'src/app/core/services/APICalls.service';

@Component({
  selector: 'app-subscription-renewal',
  templateUrl: './subscription-renewal.component.html',
  styleUrls: ['./subscription-renewal.component.scss']
})
export class SubscriptionRenewalComponent implements OnInit{
  showView:string = 'loading';
  token:any = this.route.snapshot.params['tokenId'];
  transactionId:any = this.route.snapshot.params['transactionId'];
  verifierId:any = null;
  renewalData:any = null
  subscriptionId:any = null

  verifier = {
    email:"",
    fname:"",
    mname:"",
    lname:"",
    bd:"",
    id:"",
    valueToBePaid: environment.paymentAmmout,
    remainingBalance: environment.paymentAmmout,
    goodpayTransactionLink:"",
    personaTemplateId:"",
    hasAvaliableVerificationSlot: false,
    transactionAllowed: false,
    validDateId: false,
    dateId:""
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiS: APICallsService,
  ){ }

  ngOnInit(): void{
    this.route.queryParams.subscribe({
      next:(params:any)=>{
        let passedData:any = params
        if(passedData.errors != undefined){
          let errors = passedData.errors
            this.router.navigate(['/restricted-access'],
              { queryParams: {errors} }
            )
        }
        else if(this.token != '' && this.token != null && this.token != undefined) this.renewalProcess()
        else this.router.navigate(['/restricted-access'])
      },
      error:(err)=>{
        this.router.navigate(['/restricted-access'])
      }
    })
  }

  renewalProcess(){
    const data = {
      token: this.token,
      transactionId: this.transactionId
    }
    this.apiS.apiPost(this.apiS.getVerifierStatusUrl,data)
    .subscribe({
      next:(result)=>{
        console.log(result)
        if(result?.errors){
          const errors = result.errors
          this.router.navigate(['/restricted-access'],{ queryParams: {errors} })
        }
        else if(result?.success){
          const response = result.data
          if(response?.continue){
            sessionStorage.setItem('id', response.id)
            sessionStorage.setItem('token', this.token)
            sessionStorage.setItem('transactionId', this.transactionId)
            // this.router.navigate(['/persona-verification'])
            this.renewalProcess2()
          } 
          // else this.router.navigate(['/restricted-access'])
        } 
        else this.router.navigate(['/restricted-access'])
      },
      error:(err)=>{
         //an error occured in cFunc
        //  console.error('Error: ',err)
        //  console.error('Error')
        this.router.navigate(['/restricted-access'])
      }
    })
  }

  renewalProcess2(){
    this.apiS.apiPost(this.apiS.getCheckRenewal,{id:sessionStorage.getItem('id'),transactionId:sessionStorage.getItem('transactionId')})
    .subscribe({
      next:(result)=>{
        console.log('result: ',result)
        if(result?.errors){
          // const errors = result.errors
          // this.router.navigate(['/restricted-access'],{ queryParams: {errors} })
        }
        else if(result?.success == true){
          this.verifierId = result?.data?.id
          if(result?.data?.verifier){
            const verifier = result?.data?.verifier
            console.log({verifier})
            this.verifier.email = verifier.email
            if(verifier.fname) this.verifier.fname = verifier.fname
            if(verifier.mname) this.verifier.mname = verifier.mname
            if(verifier.lname) this.verifier.lname = verifier.lname
            if(verifier.bd) this.verifier.bd = verifier.bd
            
            const resultData = result?.data?.result
            if(resultData){
              if(resultData?.amountToBePaid) this.verifier.valueToBePaid = resultData.amountToBePaid

              if(resultData?.forRenewal == true){
                this.renewalData = resultData
                this.showView = 'renewal-transaction'
              }
              else if(resultData?.forPaymentTransaction == true){
                this.router.navigate(['/persona-verification'])
              }
              else if(resultData?.renewalNotAllowed == true){
                this.showView = 'renewal-not-allowed'
              }
              else if(resultData?.earlyVerification == true){
                this.showView = 'renewal-verification'
                if(resultData?.personaTemplateId) this.verifier.personaTemplateId = resultData.personaTemplateId
                if(resultData?.subscriptionId) this.subscriptionId = resultData?.subscriptionId
                sessionStorage.setItem('subscriptionId',this.subscriptionId)
              }
              else if(resultData?.verificationAccessDenied == true){
                this.showView = 'renewal-denied-verification'
                if(resultData.personaTemplateId) this.verifier.personaTemplateId = resultData.personaTemplateId
              }
              else if(resultData?.alreadyVerified == true){
                this.router.navigate(['/verification-success'])
              }
              else if(resultData?.earlyVerificationNotYetAllowed === true){
                this.showView = 'renewal-verification-not-yet-allowed'
              }
              // else this.router.navigate(['/restricted-access'])
            }
            // else this.router.navigate(['/restricted-access'])
          }
          // else this.router.navigate(['/page-error'])
        }
        // else this.router.navigate(['/restricted-access'])
      },
      error:((err)=>{
        console.error('Error: ',err)
        // this.router.navigate(['/restricted-access'])
      })
    })
  }

  triggerRecheckVerifier(){
    this.renewalProcess()
  }

  triggerPedingTransaction(goodpayData:any){
    this.showView = 'pending-transaction'
    this.verifier.goodpayTransactionLink = goodpayData.transactionLink
    this.verifier.remainingBalance = goodpayData.remainingBalance
  }
}