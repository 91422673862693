import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

//Angular
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
// import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
// import { getFirestore, provideFirestore } from '@angular/fire/firestore';

//forms
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

//
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { PhoneMaskDirective } from './mask/phone-mask.directive';
import { DisableBackGuard } from './gaurd/disable-back.gaurd';
import { TempForwardGuard } from './gaurd/temp-forward.gaurd';
import { DisableBack2Guard } from './gaurd/disable-back-2.gaurd';


//Providers

const AngularModules = [
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
]

const FormModules = [
    FormsModule,
    ReactiveFormsModule,
]

const GlobalComponents = [
    // NavbarComponent,
    // FooterComponent,
]

const BrowserModules = [
    BrowserAnimationsModule,
    BrowserModule,
]

@NgModule({
    imports:[
        CommonModule,
        RouterModule,
        FormModules,
        BrowserModules,
        AngularModules,
    ],
    declarations: [
        // GlobalComponents,
        // PhoneMaskDirective
    ],
    exports: [
        // GlobalComponents,
    ],
    providers: [
        // GlobalComponents,
        DisableBackGuard,
        TempForwardGuard,
        DisableBack2Guard,
    ],
})
export class SharedModule {}