import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { PersonaVerificationPaymentModalComponent } from '../persona-verification-payment-modal/persona-verification-payment-modal.component';

@Component({
  selector: 'app-pending-transaction',
  templateUrl: './pending-transaction.component.html',
  styleUrls: ['./pending-transaction.component.scss']
})
export class PendingTransactionComponent implements OnInit{
    
  @Input() verifier:any;
  @Input() id:any;
  @Output() triggerRecheckVerifier = new EventEmitter<string>();
  
  proceedToGoodPayLoading:boolean = false;
  valueToBePaid:number = environment.paymentAmmout;
     
  constructor(
    public dialog: MatDialog,
    ) 
  { }

  ngOnInit(): void {
      // console.log('pending-transaction: ',this.verifier)
  }

  goTopendingTransaction(){
    this.proceedToGoodPayLoading = true
    // console.log('goTopendingTransaction',this.currentTransaction.goodpay_response.link)
    // window.open(this.currentTransaction.goodpay_response.link,'_blank')
    this.openGoodPayModal(this.verifier.goodpayTransactionLink)
    // open modal instead
  }

  openGoodPayModal(urlLink?:any){
    // get link from 'https://goodpay.ph/'
    // if(!urlLink) urlLink = 'https://google.com.ph/'

    let data = {
      urlLink: urlLink
    }

    const modalOptions = {
      disableClose: true,
      height: '90vh',
      width: '90vw',
      panelClass: 'custom-dialog-class',
      data: data
    }

    const dialogRef = this.dialog.open(PersonaVerificationPaymentModalComponent,modalOptions);

    dialogRef.afterClosed().subscribe({
      next:(dialogReturn:any)=>{
        // console.log('next',dialogReturn)
        // this.checkGoodPayTransaction()
        this.triggerRecheckVerifier.emit()
      },
      error:(err:any)=>{
        // console.log('error',err)
        // console.error('Error')
        // this.checkGoodPayTransaction()
        this.triggerRecheckVerifier.emit()
      }
    })
  }

  loadingOff(){
    this.proceedToGoodPayLoading = false
  }
}