import { first } from 'rxjs/operators';
import { FirebaseService } from '../../core/services/firebase.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { APICallsService } from 'src/app/core/services/APICalls.service';
// import { ComponentCanDeactivate } from 'src/app/shared/gaurd/disable-back.gaurd';
// import { Observable } from 'rxjs';

@Component({
  selector: 'app-parameter-verification',
  templateUrl: './paremeter-verification.component.html',
  styleUrls: ['./paremeter-verification.component.scss']
})
export class ParameterVerificationComponent implements OnInit{ // , ComponentCanDeactivate 

  token:any = this.route.snapshot.params['tokenId']
  transactionId:any = this.route.snapshot.params['transactionId']

  constructor(
    private route: ActivatedRoute,
    // private fS: FirebaseService,
    private router: Router,
    private apiS: APICallsService,
  ) 
  { }

  ngOnInit(): void {
    // console.log("starting")
    // this.router.navigate(['/page-error'])
    // sessionStorage.setItem('token', this.token)
    // this.router.navigate(['/verification-failed'])
    // this.router.navigate(['/verification-success'])

    this.route.queryParams.subscribe({
      next:(params:any)=>{
        let passedData:any = params
        if(passedData.errors != undefined){
          let errors = passedData.errors
            this.router.navigate(['/restricted-access'],
              { queryParams: {errors} }
            )
        }
        else if(this.token != '' && this.token != null && this.token != undefined) this.checkingVerifer()
        else this.router.navigate(['/restricted-access'])
      },
      error:(err)=>{
        console.error('Error: ',err)
        this.router.navigate(['/restricted-access'])
      }
    })
  }
  
  checkHasFalse(checkers:any){
    return Object.keys(checkers).some(value=> checkers[value] === false)
  }

  checkingVerifer(){
    let data = {
      token: this.token,
      transactionId: this.transactionId
    }
    this.apiS.apiPost(this.apiS.getVerifierStatusUrl,data)
    .subscribe({
      next:(result)=>{
        console.log(result)
        if(result?.errors){
          const errors = result.errors
          this.router.navigate(['/restricted-access'],{ queryParams: {errors} })
        }
        else if(result?.success){
          const response = result.data
          if(response?.continue){
            sessionStorage.setItem('id', response.id)
            sessionStorage.setItem('token', this.token)
            sessionStorage.setItem('transactionId', this.transactionId)
            this.router.navigate(['/persona-verification'])
          }
          else this.router.navigate(['/restricted-access'])
        }
        else this.router.navigate(['/restricted-access'])

        /*
        // if(result){
        //   if(result.success === true){
        //     if(result.data){
        //       if(result.data?.forVerification === true || result.data?.verificationSuccess === true || result.data?.VerificationAccessDenied == true){
        //         sessionStorage.setItem('id', result.data.id)
        //         sessionStorage.setItem('token', this.token)
        //         if(result.data.forVerification === true) this.router.navigate(['/persona-verification'])
        //         else if(result.data.verificationSuccess === true) this.router.navigate(['/verification-success']) // this.router.navigate(['/verification-success',result.data.id])
        //         else if(result.data.VerificationAccessDenied == true) this.router.navigate(['/verification-failed'])
        //       }else{
        //         if(result.data?.siteUnderMaintenance === true) this.router.navigate(['/under-maintenance'])
        //         else this.router.navigate(['/page-error'])
        //       }
        //     } // else this.router.navigate(['/restricted-access']) //no data retrieved from cFunc
        //     else this.router.navigate(['/page-error'])
        //   } else{
        //      //no verifier data
        //     this.router.navigate(['/restricted-access'])
        //   }
        // } 
        // else this.router.navigate(['/restricted-access'])  //no data retrieved from cFunc
        // */
      },
      error:(err)=>{
         //an error occured in cFunc
         console.error('Error: ',err)
        //  console.error('Error')
        this.router.navigate(['/restricted-access'])
      }
    })
  }
}
