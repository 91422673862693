<section>
                            <!-- style="height: 100vh;" -->
    <div class="container">
                            <!-- style="height: 100vh;" -->
        <div  class="d-flex justify-content-center align-items-center flex-wrap">
                            <!-- <div class="spinner-grow text-success text" style="max-width: 100px; max-height: 100px; min-width: 100px; min-height: 100px;" role="status">
                                <span class="visually-hidden ">Loading...</span>
                            </div> -->
            <img src="assets/loaders/loading.svg">
            <span class="text" >Verifying Data</span>
        </div>
    </div>
</section>