import { Component, OnInit, Output, EventEmitter, Input, NgZone} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { APICallsService } from 'src/app/core/services/APICalls.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-recredential-transaction',
  templateUrl: './recredential-transaction.component.html',
  styleUrls: ['./recredential-transaction.component.scss']
})
export class RecredentialTransactionComponent implements OnInit{
  @Input('verifierId') verifierId:any
  @Input('recredentialData') recredentialData:any
  @Input('transactionId') transactionId: any;
  @Input('verifier') verifier:any
  @Output() triggerPedingTransaction = new EventEmitter<string>();
  @Output() triggerRecheckVerifier = new EventEmitter<string>();

  proceedToPayMongoLoading:boolean = false;

  createTransactionForm = new FormGroup({
    contactNo: new FormControl('',{validators:[
      // Validators.required,
      Validators.minLength(12),
      Validators.pattern(/(\d{0,9})/g),
    ]})
  })
    
  createTransactionFormCheck = {
    contactNo: [
      {type: 'required', message: 'Phone No. is required for payment'},
      // {type: 'pattern', message: 'Phone No. must contain numbers only'},
      {type: 'minlength', message: 'Phone No. must contain atleast 10 digits.'},
      // {type: 'maxlength', message: 'Phone No. must contain atmost 10 digits.'},
    ]
  }

  showDateFormat:string = 'MMMM DD, YYYY'

  constructor(
    // private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private apiS: APICallsService,
  ){ }

  ngOnInit(): void{
    console.log(this.recredentialData)
  }

  createTransaction(){
    // create payment transaction
    this.validateFields()
    this.proceedToPayMongoLoading = true;
    if(this.createTransactionForm.valid){
      // go to server with id and contact number
      // create transaction
      const createTransactionVal = this.createTransactionForm.value
      const contact_num = createTransactionVal.contactNo.replace(/[^0-9]/g,'')
      
      const verifierData = {
        id: this.verifierId,
        contactNum: contact_num,
        transactionId: this.transactionId,
      }

      this.apiS.apiPost(this.apiS.forRecredentialCreatePayMongoTransaction,verifierData)
      .subscribe({
        next:(apiResult)=>{
          console.log('createTransaction: ',apiResult)
          if(apiResult){
            // console.log('createTransaction: ',apiResult)
            if(apiResult.success === true){
              if(apiResult.data){
                const paymongoData = apiResult.data
                if(paymongoData){
                  if(paymongoData.forPayment === true){
                    if(paymongoData.link){
                      window.location.replace(paymongoData.link)
                    }
                    else {
                      // no link contact help
                      this.proceedToPayMongoLoading = false;
                      this.ngZone.run(()=>this.router.navigate(['/page-error']))
                    }
                  }
                  else if(paymongoData.forPedingTransaction === true){
                    if(paymongoData.paymongoTransactionLink){
                      // emit paymongoData back to parent
                      this.triggerPedingTransaction.emit(paymongoData)
                    }
                    else{
                      // no transaction link contact help
                      this.proceedToPayMongoLoading = false;
                      this.ngZone.run(()=>this.router.navigate(['/page-error']))
                    }
                  }
                }else{
                  // no result.data contact help
                  this.proceedToPayMongoLoading = false;
                  this.ngZone.run(()=>this.router.navigate(['/page-error']))
                }
              }
              else{
                // no verifier data
                this.proceedToPayMongoLoading = false;
                this.ngZone.run(()=>this.router.navigate(['/page-error']))
              }
            }else{
              // result success false contact help
              this.proceedToPayMongoLoading = false;
              this.ngZone.run(()=>this.router.navigate(['/page-error']))
            }
          }else{
            // this.proceedToPayMongoLoading = false;
            // no result from server contact help
            this.ngZone.run(()=>this.router.navigate(['/page-error']))
          }
          // this.proceedToPayMongoLoading = false;
        },
        error:(err)=>{
          // error occured within the server that doesnt have an error handler server / returned bad request
          console.error('Error: ',err)
          // console.error('Error')
          this.proceedToPayMongoLoading = false;
          this.ngZone.run(()=>this.router.navigate(['/page-error']))
        }
      })
    }
    else{
      this.proceedToPayMongoLoading = false;
      console.error('Invalid forms')
      // this.ngZone.run(()=>this.router.navigate(['/restricted-access']))
    }
  }

  validateFields(): void {
    if (!this.createTransactionForm.valid) {
      // Mark the form and inputs as touched so the errors messages are shown
      this.createTransactionForm.markAsTouched();
      for (const control in this.createTransactionForm.controls) {
        if (this.createTransactionForm.controls.hasOwnProperty(control)) {
          this.createTransactionForm.controls[control].markAllAsTouched();
          this.createTransactionForm.controls[control].markAsDirty();
        }
      }
    }
  }

  isTouched(controlName:any):boolean{
    return (this.createTransactionForm.get(controlName) as FormControl).touched;
  }

  hasError(controlName:any, validType:any){
    return ((this.createTransactionForm.get(controlName) as FormControl).hasError(validType) 
    && ((this.createTransactionForm.get(controlName) as FormControl).dirty 
    || (this.createTransactionForm.get(controlName) as FormControl).touched));
  }
}