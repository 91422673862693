import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { APICallsService } from 'src/app/core/services/APICalls.service';

@Component({
  selector: 'app-verifier-recredential',
  templateUrl: './verifier-recredential.component.html',
  styleUrls: ['./verifier-recredential.component.scss']
})
export class VerifierReCredentialComponent implements OnInit{
  showView:string = 'loading';
  token:any = this.route.snapshot.params['tokenId'];
  transactionId:any = this.route.snapshot.params['transactionId']
  verifierId:any = null;
  verifier:any = null
  recredentialData:any = null

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiS: APICallsService,
  ){ }

  ngOnInit(): void{ 
    
    // for payment
    // 
    this.route.queryParams.subscribe({
      next:(params:any)=>{
        let passedData:any = params
        if(passedData.errors != undefined){
          let errors = passedData.errors
            this.router.navigate(['/restricted-access'],
              { queryParams: {errors} }
            )
        }
        else if(this.token != '' && this.token != null && this.token != undefined) this.recredentialProcess()
        else this.router.navigate(['/restricted-access'])
      },
      error:(err)=>{
        this.router.navigate(['/restricted-access'])
      }
    })
  }

  recredentialProcess(){
    const data = {
      token: this.token,
      transactionId: this.transactionId
    }
    this.apiS.apiPost(this.apiS.getVerifierStatusUrl,data)
    .subscribe({
      next:(result)=>{
        console.log(result)
        if(result?.errors){
          const errors = result.errors
          this.router.navigate(['/restricted-access'],{ queryParams: {errors} })
        }
        else if(result?.success){
          const response = result.data
          if(response?.continue){
            sessionStorage.setItem('id', response.id)
            sessionStorage.setItem('token', this.token)
            sessionStorage.setItem('transactionId', this.transactionId)
            // this.router.navigate(['/persona-verification'])
            this.recredentialProcess2()
          } 
          else this.router.navigate(['/restricted-access'])
        } 
        else this.router.navigate(['/restricted-access'])
      },
      error:(err)=>{
         //an error occured in cFunc
         console.error('Error: ',err)
        //  console.error('Error')
        this.router.navigate(['/restricted-access'])
      }
    })
  }

  recredentialProcess2(){
    this.apiS.apiPost(this.apiS.getCheckRecredential,{id:sessionStorage.getItem('id'),transactionId:sessionStorage.getItem('transactionId')})
    .subscribe({
      next:(result)=>{
        console.log('result: ',result)
        if(result.success == true){
          const resultData = result?.data
          this.verifierId = resultData?.id
          this.verifier = resultData?.verifier
          if(resultData.forPayment){
            this.showView = 'recredential-transaction'
            this.recredentialData = resultData?.data
          }
          else if(resultData.updated || resultData.alreadyApproved){
            this.showView = 'recredential-updated'
          }
          else if(resultData.noRecredential){
            this.showView = 'no-recredential'
          }
          else{
            this.router.navigate(['/restricted-access'])
          }
        }
        else{
          this.router.navigate(['/restricted-access'])
        }
      },
      error:(err)=>{
        console.error('Error: ',err)
        this.router.navigate(['/restricted-access'])
      }
    })
  }

  triggerRecheckVerifier(){
    this.recredentialProcess()
  }

  triggerPedingTransaction(goodpayData:any){
    this.showView = 'pending-transaction'
    this.verifier.goodpayTransactionLink = goodpayData.transactionLink
    this.verifier.remainingBalance = goodpayData.remainingBalance
  }
}