import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class APICallsService{
    private secServer = {
        checkVerifierStatusUrl: environment.secServerBaseUrl+'/server/v1'+environment.environmentURLCode+'/checkVerifierStatus',
        fetchVerifierDataUrl: environment.secServerBaseUrl+'/server/v1'+environment.environmentURLCode+'/fetchVerifierData',
        createGoodPayTransactionUrl: environment.secServerBaseUrl+'/server/v1'+environment.environmentURLCode+'/createGoodPayTransaction',
        createPayMongoTransactionUrl: environment.secServerBaseUrl+'/server/v1'+environment.environmentURLCode+'/createPayMongoTransaction',
        checkPaymentTransactionUrl: environment.secServerBaseUrl+'/server/v1'+environment.environmentURLCode+'/checkPaymentTransaction',
        checkVerificationAllowedToday: environment.secServerBaseUrl+'/server/v1'+'/checkTransactionAllowedToday',
        increaseTodayReserveCounter: environment.secServerBaseUrl+'/server/v1'+'/increaseTodayReserveCounter',
        decreaseDateReserveCounter: environment.secServerBaseUrl+'/server/v1'+'/decreaseDateReserveCounter',
        uploadKYCData: environment.secServerWithIPBaseUrl+'/server/v1'+environment.environmentURLCode+'/uploadKYCData',
        fetchVerificationCountAccess: environment.secServerBaseUrl+'/server/v1'+environment.environmentURLCode+'/fetchVerificationCountAccess',
        fetchRenewalVerificationCountAccess: environment.secServerBaseUrl+'/server/v1'+environment.environmentURLCode+'/fetchRenewalVerificationCountAccess',
        checkRenewal: environment.secServerBaseUrl+'/server/v1'+environment.environmentURLCode+'/checkRenewal',
        forRenewalCreateGoodPayTransaction: environment.secServerBaseUrl+'/server/v1'+environment.environmentURLCode+'/forRenewalCreateGoodPayTransaction',
        forRenewalCreatePayMongoTransaction: environment.secServerBaseUrl+'/server/v1'+environment.environmentURLCode+'/forRenewalCreatePayMongoTransaction',
        checkRenewalPaymentTransactionUrl: environment.secServerBaseUrl+'/server/v1'+environment.environmentURLCode+'/checkRenewalPaymentTransaction',
        uploadRenewalKYCData: environment.secServerWithIPBaseUrl+'/server/v1'+environment.environmentURLCode+'/uploadRenewalKYCData',
        checkRecredential: environment.secServerBaseUrl+'/server/v1'+environment.environmentURLCode+'/checkRecredential',
        forRecredentialCreateGoodPayTransaction: environment.secServerBaseUrl+'/server/v1'+environment.environmentURLCode+'/forRecredentialCreateGoodPayTransaction',
        forRecredentialCreatePayMongoTransaction: environment.secServerBaseUrl+'/server/v1'+environment.environmentURLCode+'/forRecredentialCreatePayMongoTransaction',
        checkRecredentialPaymentTransaction: environment.secServerBaseUrl+'/server/v1'+environment.environmentURLCode+'/checkRecredentialPaymentTransaction',
    }
    private checkTransactionAllowedUrl = environment.secServerBaseUrl+'/server/v1'+'/checkTransactionAllowedToday'
    private fetchDateTodayUrl = environment.secServerBaseUrl+'/secVerificationTransaction/api/v1/fetchDateToday'
    private proxyCreateAPIUrl = environment.proxyCreateAPIUrl
    private proxyFetchAPIUrl = environment.proxyFetchAPIUrl

    constructor(
        private http: HttpClient,
    ){}

    apiPost(url:string,data:any){
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json'
          })
        };
        return this.http.post<any>(url,data,httpOptions)
    }

    apiGet(url:string){
        return this.http.get<any>(url)
    }

    get getUploadKYCData(){
        return this.secServer.uploadKYCData
    }

    get getIncreaseTodayReserveCounterURL(){
        return this.secServer.increaseTodayReserveCounter
    }

    get getDecreaseDateReserveCounterURL(){
        return this.secServer.decreaseDateReserveCounter
    }

    get getCheckVerificationAllowedTodayUrl(){
        return this.secServer.checkVerificationAllowedToday
    }

    get getCreateGoodPayTransactionUrl(){
        return this.secServer.createGoodPayTransactionUrl
    }

    get getCreatePayMongoTransactionUrl(){
        return this.secServer.createPayMongoTransactionUrl
    }

    get getCheckPaymentTransactionUrl(){
        return this.secServer.checkPaymentTransactionUrl
    }

    get getVerifierStatusUrl(){
        return this.secServer.checkVerifierStatusUrl
    }

    get getVerifierDataUrl(){
        return this.secServer.fetchVerifierDataUrl
    }

    get getFetchVerificationCountAccessUrl(){
        return this.secServer.fetchVerificationCountAccess
    }

    get getFetchRenewalVerificationCountAccessUrl(){
        return this.secServer.fetchRenewalVerificationCountAccess
    }

    get getCheckRenewal(){
        return this.secServer.checkRenewal
    }

    get forRenewalCreateGoodPayTransaction(){
        return this.secServer.forRenewalCreateGoodPayTransaction
    }

    get forRenewalCreatePayMongoTransaction(){
        return this.secServer.forRenewalCreatePayMongoTransaction
    }

    get getCheckRenewalPaymentTransactionUrl(){
        return this.secServer.checkRenewalPaymentTransactionUrl
    }

    get getRenewalUploadKYCData(){
        return this.secServer.uploadRenewalKYCData
    }

    get getCheckRecredential(){
        return this.secServer.checkRecredential
    }

    get forRecredentialCreateGoodPayTransaction(){
        return this.secServer.forRecredentialCreateGoodPayTransaction
    }

    get forRecredentialCreatePayMongoTransaction(){
        return this.secServer.forRecredentialCreatePayMongoTransaction
    }

    get getCheckRecredentialPaymentTransaction(){
        return this.secServer.checkRecredentialPaymentTransaction
    }

    get getCheckTransactionAllowedUrl(){
        return this.checkTransactionAllowedUrl
    }

    get getProxyCreateAPIUrl(){
        return this.proxyCreateAPIUrl
    }

    get getProxyFetchAPIUrl(){
        return this.proxyFetchAPIUrl
    }

    get getFetchDateTodayUrl(){
        return this.fetchDateTodayUrl
    }
}