import { Component, OnInit, Output, EventEmitter, Input, NgZone} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-recredential-updated',
  templateUrl: './recredential-updated.component.html',
  styleUrls: ['./recredential-updated.component.scss']
})
export class RecredentialUpdatedComponent implements OnInit{
  @Input('verifierId') verifierId:any
  @Input('verifier') verifier:any
  
  constructor(
    private router: Router,
    private ngZone: NgZone,
  ){ }

  ngOnInit(): void{

  }

  proceedToVerification(){
    sessionStorage.setItem('id', this.verifierId)
    this.ngZone.run(()=>this.router.navigate(['/persona-verification']))
  }
}