<section>
  <div class="container" style="height: 100vh;">
    <div style="height: 100vh;" class="d-flex justify-content-center align-items-center">
      <div class="form-group card shadow" style="width: 50em;background-color: rgb(90, 216, 164); padding:2em; color:whitesmoke">
        <div style="color:rgb(166, 180, 37)"><h1>Page Under Maintenance</h1></div>
        <!-- <hr>
        <div class="d-flex justify-content-between">
          <button class="btn btn-success" (click)="goBack()">
              Back to SEC Philippines
          </button>
        </div> -->
      </div>
    </div>
  </div>
</section>