<!-- <ng-container *ngSwitch="showView">
    <section *ngSwitchCase="'create-transaction'">
        <app-create-transaction [verifier]="verifier" [id]="token" 
            (triggerPedingTransaction)="triggerPedingTransaction($event)"
            (triggerRecheckVerifier)="triggerRecheckVerifier()">
        </app-create-transaction>
    </section>
    <section *ngSwitchCase="'pending-transaction'">
        <app-pending-transaction [verifier]="verifier" [id]="token"
            (triggerRecheckVerifier)="triggerRecheckVerifier()">
        </app-pending-transaction>
    </section>
    <section *ngSwitchCase="'verification'">
        <app-verify-user [verifier]="verifier" [id]="token" (triggerGoBackToSec)="goBackToSec()"></app-verify-user>
    </section>
    <section *ngSwitchCase="'loading'">
        <div class="container container-loader">
            <div class="d-flex justify-content-center align-items-center flex-wrap">
                <img src="assets/loaders/loading.svg">
                <span class="text">Loading</span>
            </div>
        </div>
    </section>
</ng-container> -->

<!-- payment to unlock -->
<section *ngIf="showView == 'create-transaction'">
    <app-create-transaction [verifier]="verifier" [id]="token" 
        (triggerPedingTransaction)="triggerPedingTransaction($event)"
        (triggerRecheckVerifier)="triggerRecheckVerifier()">
    </app-create-transaction>
</section>

<!-- pending to unlock -->
<section *ngIf="showView == 'pending-transaction'">
    <app-pending-transaction [verifier]="verifier" [id]="token"
        (triggerRecheckVerifier)="triggerRecheckVerifier()">
    </app-pending-transaction>
</section>

<!-- verification -->
<section *ngIf="showView == 'verification'">
    <app-verify-user [verifier]="verifier" [id]="token" (triggerGoBackToSec)="goBackToSec()"></app-verify-user>
</section>

<!-- loading -->
<section *ngIf="showView == 'loading'">
    <div class="container container-loader">
        <div class="d-flex justify-content-center align-items-center flex-wrap">
            <img src="assets/loaders/loading.svg">
            <span class="text">Loading</span>
        </div>
    </div>
</section>