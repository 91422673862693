// import { FirebaseService } from '../../core/services/firebase.service';
import { Router } from '@angular/router';
import { Component, NgZone, OnInit } from '@angular/core';
import { APICallsService } from 'src/app/core/services/APICalls.service';
import { environment } from 'src/environments/environment';
// import { ComponentCanDeactivate } from 'src/app/shared/gaurd/disable-back.gaurd';
// import { Observable } from 'rxjs';

@Component({
  selector: 'app-failed-verification',
  templateUrl: './failed-verification.component.html',
  styleUrls: ['./failed-verification.component.scss']
})
export class FailedVerificationComponent implements OnInit { // , ComponentCanDeactivate 

  // id:any;
  buttonLoading = true;
  showErrors = false
  errors:any = []
  showReverification = false;
  token:any;
  accessCount:any=null;

  shownErrors = ['id_error','same_id','failed_selfie','name_mismatch','birthday_mismatch',];

  constructor(
    // private route: ActivatedRoute,
    // private fS: FirebaseService,
    private router: Router,
    private ngZone: NgZone,
    private apiS: APICallsService,
  ) 
  { }

  ngOnInit(): void {
    // this.id = this.route.snapshot.params['id']
    this.token = sessionStorage.getItem('token')
    // console.log('token: ',this.token)
    if(this.token){
      this.apiS.apiGet(this.apiS.getFetchVerificationCountAccessUrl+'/'+this.token)
      .subscribe({
        next:(apiResult)=>{
          // console.log('apiResult: ',apiResult)
          if(apiResult.success == true){
            if(apiResult?.data?.failures?.fails.length > 0 || apiResult?.data?.failures?.nested.length > 0){
              this.errors = apiResult.data.failures
              this.showErrors = true
            }

            if(apiResult?.data?.accessLeft >= 0) this.accessCount = apiResult.data.accessLeft

            if(apiResult?.data?.reVerification == true){
              this.showReverification = true
              this.buttonLoading = false;
            }
            else if(apiResult?.data?.siteUnderMaintenance == true) this.router.navigate(['/under-maintenance'])
            else {
              this.buttonLoading = false
            };
          } else this.buttonLoading = false;
        },
        error:(err)=>{
          // console.error('Error: ',err)
          this.buttonLoading = false;
        }
      })
    }
    else this.buttonLoading = false;
  }

  reverifyKYC(){
    if(this.showReverification == true)
      this.ngZone.run(() => { this.router.navigate(['/verifying',this.token])});
  }

  // @HostListener('window:beforeunload')
  // canDeactivate(): Observable<boolean> | boolean {
  //   return false
  // }

  // goToKYC(){
  //   this.ngZone.run(() => { this.router.navigate(['/persona-verification',this.id])});
  //   // this.router.navigate(['/persona-verification',this.id])
  // }

  // flatCode(fail:string){
  //   switch(fail){
  //     case ''
  //   }
  // }

  goBack(){
    window.location.replace(environment.secureCallback);
  }
}
