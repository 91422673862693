<div class="container" style="height: 100vh;">
    <div style="height: 100vh;" class="d-flex justify-content-center align-items-center">
        <div class="form-group card shadow" style="width: 40em;background-color: rgb(90, 216, 164); padding:2em;">
                            <!-- goodpay logo -->
            <div class="row">
                <div class="col">
                    <div class="goodpay_image">
                        <div class="img-wrapper">
                            <img src="assets/images/goodpay/goodpay_logo.jpg">
                        </div>
                    </div>
                </div>
            </div>

                            <!-- <div class="row">
                                <div class="col flex justify-content-center">
                                    <i>Note: refresh this page after payment</i>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4 fw-bold d-flex align-items-center">Paid Amount</div>
                                <div class="col-1 fw-bold d-flex align-items-center">:</div>
                                <div class="col d-flex align-items-center"><input class="form-control" readonly disabled [ngModel]="paid_amount" type="text" ></div>
                            </div> -->

            
            <div class="row">
                <div class="col-4 fw-bold d-flex align-items-center">Amount</div>
                <div class="col-1 fw-bold d-flex align-items-center">:</div>
                <div class="col d-flex align-items-center"><input class="form-control" readonly disabled [ngModel]="verifier.remainingBalance" type="text" ></div>
            </div>
            
            <div class="row">
                <div class="col flex justify-content-center">
                    <button type="button" [disabled]="proceedToGoodPayLoading" (click)="goTopendingTransaction()" class="btn btn-primary w-100">
                        <span [hidden]="!proceedToGoodPayLoading">
                            <img style="margin-right: 1em; width: 2rem; height: 2rem;" src="assets/loaders/loading.svg">
                        </span>
                        Proceed to GoodPay
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>