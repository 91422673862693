
<ng-container [ngSwitch]="showView">
  <!-- renewal-transaction  -->
  <section  *ngSwitchCase="'renewal-transaction'">
    <app-renewal-transaction [verifier]="verifier" [verifierId]="verifierId" [renewalData]="renewalData" [transactionId]="transactionId"
    (triggerPedingTransaction)="triggerPedingTransaction($event)"
    (triggerRecheckVerifier)="triggerRecheckVerifier()">
    </app-renewal-transaction>
  </section>

  <!-- verification -->
  <section  *ngSwitchCase="'renewal-verification'">
    <app-renewal-verify-user [verifier]="verifier" [id]="verifierId" [subscriptionId]="subscriptionId" [transactionId]="transactionId"></app-renewal-verify-user>
  </section>

  <!-- renewal-not-yet-allowed -->
  <section  *ngSwitchCase="'renewal-verification-not-yet-allowed'">
    <app-renewal-verification-not-yet-allowed></app-renewal-verification-not-yet-allowed>
  </section>

  <!-- renewal-not-allowed -->
  <section  *ngSwitchCase="'renewal-not-allowed'">
    <app-renewal-not-allowed></app-renewal-not-allowed>
  </section>

  <!-- loading -->
  <section *ngSwitchCase="'loading'">
    <div class="container container-loader">
        <div class="d-flex justify-content-center align-items-center flex-wrap">
            <img src="assets/loaders/loading.svg">
            <span class="text">Loading</span>
        </div>
    </div>
  </section>
</ng-container>