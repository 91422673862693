import { Component, OnInit, } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-renewal-not-allowed',
  templateUrl: './renewal-not-allowed.component.html',
  styleUrls: ['./renewal-not-allowed.component.scss']
})
export class RenewalNotAllowedComponent implements OnInit{
  token:any;// token:any = this.route.snapshot.params['tokenId']

  constructor(
    // private route: ActivatedRoute,
    private router: Router,
    // private ngZone: NgZone,
    // public dialog: MatDialog,
    // private apiS: APICallsService,
  ) 
  { }

  ngOnInit(): void {
    this.token = sessionStorage.getItem('id')
    // if(this.token != '' && this.token != null && this.token != undefined) this.checkVerifier()
    // else this.router.navigate(['/restricted-access'])
  }
}