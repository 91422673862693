<section>
  <div class="container">
    <div class="d-flex justify-content-center">
      <div class="form-group card shadow notice-card">
        <!-- style="width: 50em;background-color: rgb(216, 105, 90); padding:2em; color:whitesmoke" -->
        <div>
            <div class="notice-loader card d-flex justify-content-center align-items-center flex-wrap flex-row">
              <ng-container [ngSwitch]="status">
                <ng-container *ngSwitchCase="'paid'">
                  <div class="loader-size d-flex justify-content-center align-items-center">
                    <mat-icon class="loader-icon green-color">check_circle_outline</mat-icon>
                    <span class="text" >Payment Confirmed</span>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'noRecredential'">
                  <div class="loader-size d-flex justify-content-center align-items-center">
                    <mat-icon class="loader-icon green-color">close</mat-icon>
                    <span class="text" >No Recredential Update</span>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'noVerification'">
                  <div class="loader-size d-flex justify-content-center align-items-center">
                    <mat-icon class="loader-icon green-color">close</mat-icon>
                    <span class="text" >No Valid Verification</span>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'paymentFailed'">
                  <div class="loader-size d-flex justify-content-center align-items-center">
                    <mat-icon class="loader-icon green-color">close</mat-icon>
                    <span class="text" >Payment Failed</span>
                  </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <img class="loader-size" src="assets/loaders/loading.svg">
                  <span class="text" >Confirming Payment</span>
                </ng-container>
              </ng-container>
            </div>
        </div>
        <div class="row-div">
          <button *ngIf="!(status=='paid' || status=='noRecredential' || status=='noVerification')" type="button" class="btn btn-primary w-100 row-div">
            <span>Proceed to Repayment</span>
          </button>
          <button type="button" (click)="continueProcess()" *ngIf="!status || status=='paid'" [disabled]="status!='paid'" class="btn btn-primary w-100 row-div">
            <span>Continue</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>