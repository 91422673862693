import { Component, OnInit, NgZone, HostListener, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { APICallsService } from 'src/app/core/services/APICalls.service';
import { PendingTransactionComponent } from './pending-transaction/pending-transaction.component';

@Component({
  selector: 'app-persona-verification.component',
  templateUrl: './persona-verification.component.html',
  styleUrls: ['./persona-verification.component.scss']
})
export class PersonaVerificationComponent implements OnInit{ //, ComponentCanDeactivate 

  @ViewChild(PendingTransactionComponent) pendingTranscationChild:null|PendingTransactionComponent = null;
  
  loader: boolean = true;
  token:any;// token:any = this.route.snapshot.params['tokenId']
  currentVerifier:any;
  showView:string = 'loading'; // create-transaction, verification,
  valueToBePaid:number = environment.paymentAmmout;
  remaining_balance:number = this.valueToBePaid;
  // onGoingVerification:boolean = false;

  verifier = {
    email:"",
    fname:"",
    mname:"",
    lname:"",
    bd:"",
    id:"",
    valueToBePaid: environment.paymentAmmout,
    remainingBalance: environment.paymentAmmout,
    goodpayTransactionLink:"",
    personaTemplateId:"",
    hasAvaliableVerificationSlot: false,
    transactionAllowed: false,
    validDateId: false,
    dateId:""
  };

  transactionAllowed = {
    weekday: true,
    verificationSlot: true,
    internalError:false,
    validDateId:true,
  };

  createTransactionForm = new FormGroup({
    contactNo: new FormControl('',{validators:[
      // Validators.required,
      Validators.minLength(12),
      Validators.pattern(/(\d{0,9})/g),
    ]})
  })

  createTransactionFormCheck = {
    contactNo: [
      {type: 'required', message: 'Phone No. is required for payment'},
      // {type: 'pattern', message: 'Phone No. must contain numbers only'},
      {type: 'minlength', message: 'Phone No. must contain atleast 10 digits.'},
      // {type: 'maxlength', message: 'Phone No. must contain atmost 10 digits.'},
    ]
  }

  months = ['January','February','March','April','May','June','July','August','September','October','November','December']

  constructor(
    // private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    public dialog: MatDialog,
    private apiS: APICallsService,
  ) 
  { }

  // @HostListener("window:beforeunload", ["$event"])
  // unloadHandler(event: Event){
    // if(this.onGoingVerification == true && this.dateId)
    // if(this.onGoingVerification == true){
    //   this.apiS.apiGet(this.apiS.getDecreaseDateReserveCounterURL)
    //   .subscribe({
    //       next:(apiResult)=>{
    //           // console.log('apiResult: ',apiResult)
    //       },
    //       error:(err)=>{
    //           // console.log('Error: ',err)
    //           console.error('Error')
    //       }
    //   })
    // }
  // }

  ngOnInit(): void {
    // eyJlbWFpbCI6Imtlbm5ldGguZGV2ZXJhMTBAZ21haWwuY29tIiwiaWQiOiJhd2Q5MWRxd2R3In0=
    // console.log(this.token)
    // console.log('version: '+1)


    this.token = sessionStorage.getItem('id')
    if(this.token != '' && this.token != null && this.token != undefined) this.checkVerifier()
    else this.router.navigate(['/restricted-access'])
  }

  checkVerifier(){
    this.loader = false
    try{  
      let data = {
        id: this.token,
        // skipPayment: environment.skip.goodPay.payment,
      }
      this.apiS.apiPost(this.apiS.getVerifierDataUrl,data)
      .subscribe({
        next:(result:any)=>{
          if(this.pendingTranscationChild)this.pendingTranscationChild?.loadingOff()
          if(result){
            if(result.success === true){
              if(result.verifier){
                const verifier = result.verifier
                this.verifier.email = verifier.email
                if(verifier.fname) this.verifier.fname = verifier.fname
                if(verifier.mname) this.verifier.mname = verifier.mname
                if(verifier.lname) this.verifier.lname = verifier.lname
                if(verifier.bd) this.verifier.bd = verifier.bd
                // if(verifier.bd){
                //   let year; let month; let day; 
                //   try{
                //     let date = new Date(verifier.bd)
                //     year = date.getFullYear()
                //     month = this.months[date.getMonth()]
                //     day = date.getDate()
                //     this.verifier.bd = month+" "+day+", "+year
                //   }catch(err){
                //     // console.error(err)
                //     // console.error('Error')
                //   }
                // }

                if(verifier.result){
                  if(verifier.result.amountToBePaid) this.verifier.valueToBePaid = verifier.result.amountToBePaid
                  this.currentVerifier = verifier
                  
                  const resultData = verifier.result

                  if(resultData.forPaymentTransaction === true){
                    this.showView = 'create-transaction'
                  }
                  
                  else if(resultData.forPedingTransaction === true){
                    if(resultData.goodpayTransactionLink) this.verifier.goodpayTransactionLink = resultData.goodpayTransactionLink
                    if(resultData.remainingBalance) this.verifier.remainingBalance = resultData.remainingBalance
                    this.showView = 'pending-transaction'
                  }

                  else if(resultData.forPersonaVerification === true){
                    if(resultData.personaTemplateId) this.verifier.personaTemplateId = resultData.personaTemplateId
                    // if(resultData.forPersonaVerification) this.verifier.forPersonaVerification = resultData.forPersonaVerification
                    if(resultData.transactionAllowed) this.verifier.transactionAllowed = resultData.transactionAllowed
                    if(resultData.hasAvaliableVerificationSlot) this.verifier.hasAvaliableVerificationSlot = resultData.hasAvaliableVerificationSlot
                    if(resultData.dateId) this.verifier.dateId = resultData.dateId
                    if(resultData.validDateId) this.verifier.validDateId = resultData.validDateId
                    this.showView = 'verification'
                  }

                  else if(resultData.contactHelp === true){
                    this.ngZone.run(()=>this.router.navigate(['/page-error']))
                  }

                  else{
                    // unkown next step
                    // let errors = ['Internal error']
                    // this.ngZone.run(() => {this.router.navigate(['/restricted-access'],
                    // {queryParams:{errors}})});
                    this.ngZone.run(()=>this.router.navigate(['/page-error']))
                  }


                }else{
                  this.currentVerifier = verifier
                  // unkown next step
                  // let errors = ['Internal error']
                  // this.ngZone.run(() => {this.router.navigate(['/restricted-access'],
                  // {queryParams:{errors}})});

                  this.ngZone.run(()=>this.router.navigate(['/page-error']))
                }

              } else this.router.navigate(['/restricted-access']) //no data retrieved from cFunc
            } else{
              // if(result.errorData){
                
              // }else{
              //   // this.router.navigate(['/restricted-access'])
              // }
              // this.router.navigate(['/restricted-access'])
              this.router.navigate(['/page-error'])
            } //no verifier data
          } else this.router.navigate(['/restricted-access']) //no data object retrieved
        },
        error:(err)=>{
          if(this.pendingTranscationChild)this.pendingTranscationChild?.loadingOff()
          // let errors = ['Internal error']
          // this.ngZone.run(() => {this.router.navigate(['/restricted-access'],
          // {queryParams:{errors}})});
          this.router.navigate(['/page-error'])
        }
      })
    }
    catch(err){
      if(this.pendingTranscationChild)this.pendingTranscationChild?.loadingOff()
      this.router.navigate(['/page-error'])
      // let errors = ['Internal error']
      // this.ngZone.run(() => {this.router.navigate(['/restricted-access'],
      // {queryParams:{errors}})});
    }
  }

  triggerRecheckVerifier(){
    this.checkVerifier()
  }

  triggerPedingTransaction(goodpayData:any){
    this.showView = 'pending-transaction'
    this.verifier.goodpayTransactionLink = goodpayData.transactionLink
    this.verifier.remainingBalance = goodpayData.remainingBalance
  }

  // triggerOngoingVerification(ongoingVerification:boolean){
  //   this.onGoingVerification = ongoingVerification
  // }

  isTouched(controlName:any):boolean{
    return (this.createTransactionForm.get(controlName) as FormControl).touched;
  }

  hasError(controlName:any, validType:any){
    return ((this.createTransactionForm.get(controlName) as FormControl).hasError(validType) 
    && ((this.createTransactionForm.get(controlName) as FormControl).dirty 
    || (this.createTransactionForm.get(controlName) as FormControl).touched));
  }

  validateFields(): void {
    if (!this.createTransactionForm.valid) {
      // Mark the form and inputs as touched so the errors messages are shown
      this.createTransactionForm.markAsTouched();
      for (const control in this.createTransactionForm.controls) {
        if (this.createTransactionForm.controls.hasOwnProperty(control)) {
          this.createTransactionForm.controls[control].markAllAsTouched();
          this.createTransactionForm.controls[control].markAsDirty();
        }
      }
    }
  }
  
  goBackToSec(){
    window.location.replace(environment.secureCallback);
  }
}
