// import { FirebaseService } from './../../core/services/firebase.service';
// import { ActivatedRoute, Router } from '@angular/router';
import { Component, NgZone, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
// import { ComponentCanDeactivate } from 'src/app/shared/gaurd/disable-back.gaurd';
// import { Observable } from 'rxjs';

@Component({
  selector: 'app-success-verification',
  templateUrl: './success-verification.component.html',
  styleUrls: ['./success-verification.component.scss']
})
export class SuccessVerificationComponent implements OnInit { // , ComponentCanDeactivate 
  
  // name:any = "";
  // fname:any = "";
  // mname:any = "";
  // lname:any = "";
  // kyc_fname:any = "";
  // kyc_mname:any = "";
  // kyc_lname:any = "";
  // kyc_email:any = "";
  // kyc_bday:any = "";
  // inqid:any = "";
  // email:any  = "";
  // phone:any  = "";
  // bday:any  = "";
  // address:any  = "";
  // sss:any  = "";
  // idclass:any  = "";
  // idnum:any  = "";
  // currentVerifier:any;

  constructor(
    // private route: ActivatedRoute,
    // private fS: FirebaseService,
    // private router: Router,
    // private ngZone: NgZone
  ) 
  { }

  ngOnInit(): void {
    // const id = this.route.snapshot.params['id']
    // // console.log('in success')
    // this.fS.getDocByRefSnapshot(environment.verificationC+'/'+id)
    // .subscribe((result:any)=>{
    //   const verifier = result.data()
    //   verifier.id =result.id
    //   this.currentVerifier = verifier
    //   // console.log(this.currentVerifier)
    //   if(this.currentVerifier.email) this.email = this.currentVerifier.email
    //   if(this.currentVerifier.fname) this.fname = this.currentVerifier.fname
    //   if(this.currentVerifier.mname) this.mname = this.currentVerifier.mname
    //   if(this.currentVerifier.lname) this.lname = this.currentVerifier.lname
    //   if(this.currentVerifier.bd){
    //     let date = this.currentVerifier.bd.toDate()
    //     let year = date.getFullYear()
    //     // let month = this.months[parseInt(date.getMonth())]
    //     let month
    //     if(parseInt(date.getMonth()+1) < 10){
    //       month =(parseInt(date.getMonth()+1)).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
    //     } else month = parseInt(date.getMonth()+1)
        
    //     let day
    //     if(parseInt(date.getDate()) < 10){
    //       day = (date.getDate()).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
    //     } else day = date.getDate()

    //     this.bday = year+"-"+month+"-"+day
    //     // console.log(this.bday)
    //   }
    //   if(verifier.kyc_data){
    //     if(verifier.kyc_lvl = 0){
    //       this.router.navigate(['/persona-verification',id])
    //     }
    //     else if(verifier.kyc_lvl = 1){
    //       this.getVerifierById(verifier.kyc_data.kyc_lvl1_id)
    //       // console.log('lvl id',verifier.kyc_data.kyc_lvl1_id)
    //       // this.fS.getDocByRefSnapshot('Start-Credentialing/'+verifier.kyc_data.kyc_lvl1_id)
    //       // .subscribe({
    //       //   next:(result)=>{
    //       //     let credential:any = result.data()
    //       //     // console.log({credential})
    //       //     credential.id = result.id
    //       //     this.inqid = credential.kyc_data.inquiry_id ? credential.kyc_data.inquiry_id : ""
    //       //     this.name = (credential.kyc_data.fields.user_information.firstName ? credential.kyc_data.fields.user_information.firstName+" " : "") +""+(credential.kyc_data.fields.user_information.middleName ? credential.kyc_data.fields.user_information.middleName+" " : "")+""+(credential.kyc_data.fields.user_information.lastName ? credential.kyc_data.fields.user_information.lastName : "")
    //       //     this.kyc_fname = credential.kyc_data.fields.user_information.firstName ? credential.kyc_data.fields.user_information.firstName+" " : ""
    //       //     this.kyc_mname = credential.kyc_data.fields.user_information.middleName ? credential.kyc_data.fields.user_information.middleName+" " : ""
    //       //     this.kyc_lname = credential.kyc_data.fields.user_information.lastName ? credential.kyc_data.fields.user_information.lastName : ""
    //       //     this.kyc_email = credential.email ? credential.email : ""
    //       //     this.phone = credential.kyc_data.fields.user_information.phoneNumber ? credential.kyc_data.fields.user_information.phoneNumber : ""
    //       //     this.kyc_bday = credential.kyc_data.fields.user_information.birthdate ? credential.kyc_data.fields.user_information.birthdate : ""
    //       //     this.address = (credential.kyc_data.fields.address.street1 ? credential.kyc_data.fields.address.street1+", " : "")
    //       //       +(credential.kyc_data.fields.address.street2 ? credential.kyc_data.fields.address.street2+", " : "")
    //       //       +(credential.kyc_data.fields.address.subdivision ? credential.kyc_data.fields.address.subdivision+", " : "")
    //       //       +(credential.kyc_data.fields.address.city ? credential.kyc_data.fields.address.city+", " : "")
    //       //       +(credential.kyc_data.fields.address.country_code ? credential.kyc_data.fields.address.country_code+", " : "")
    //       //       +(credential.kyc_data.fields.address.postal_code ? credential.kyc_data.fields.address.postal_code+", " : "")
    //       //       +(credential.kyc_data.fields.address.postal_code ? "Selected "+credential.kyc_data.fields.address.postal_code : "")
    //       //     this.sss = credential.kyc_data.fields.user_information.socialSecurityNumber ? credential.kyc_data.fields.user_information.socialSecurityNumber : ""
    //       //     this.idclass = credential.kyc_data.fields.user_information.user_identification.identification_class ? credential.kyc_data.fields.user_information.user_identification.identification_class : ""
    //       //     this.idnum = credential.kyc_data.fields.user_information.user_identification.identification_number ? credential.kyc_data.fields.user_information.user_identification.identification_number : ""
    //       //   }
    //       // })
    //     }
    //     else if(verifier.kyc_lvl = 2){
    //       this.getVerifierById(verifier.kyc_data.kyc_lvl2_id)
    //       // this.fS.getDocByRefSnapshot('Start-Credentialing/'+verifier.kyc_data.kyc_lvl2_id)
    //       // .subscribe({
    //       //   next:(result:any)=>{
    //       //     let credential:any = result.data()
    //       //     credential.id = result.id
    //       //     // console.log(credential)
    //       //     this.inqid = credential.kyc_data.inquiry_id ? credential.kyc_data.inquiry_id : ""
    //       //     this.name = (credential.kyc_data.fields.user_information.firstName ? credential.kyc_data.fields.user_information.firstName+" " : "") +""+(credential.kyc_data.fields.user_information.middleName ? credential.kyc_data.fields.user_information.middleName+" " : "")+""+(credential.kyc_data.fields.user_information.lastName ? credential.kyc_data.fields.user_information.lastName : "")
    //       //     this.kyc_fname = credential.kyc_data.fields.user_information.firstName ? credential.kyc_data.fields.user_information.firstName+" " : ""
    //       //     this.kyc_mname = credential.kyc_data.fields.user_information.middleName ? credential.kyc_data.fields.user_information.middleName+" " : ""
    //       //     this.kyc_lname = credential.kyc_data.fields.user_information.lastName ? credential.kyc_data.fields.user_information.lastName : ""
    //       //     this.kyc_email = credential.email ? credential.email : ""
    //       //     this.phone = credential.kyc_data.fields.user_information.phoneNumber ? credential.kyc_data.fields.user_information.phoneNumber : ""
    //       //     this.kyc_bday = credential.kyc_data.fields.user_information.birthdate ? credential.kyc_data.fields.user_information.birthdate : ""
    //       //     this.address = (credential.kyc_data.fields.address.street1 ? credential.kyc_data.fields.address.street1+", " : "")
    //       //       +(credential.kyc_data.fields.address.street2 ? credential.kyc_data.fields.address.street2+", " : "")
    //       //       +(credential.kyc_data.fields.address.subdivision ? credential.kyc_data.fields.address.subdivision+", " : "")
    //       //       +(credential.kyc_data.fields.address.city ? credential.kyc_data.fields.address.city+", " : "")
    //       //       +(credential.kyc_data.fields.address.country_code ? credential.kyc_data.fields.address.country_code+", " : "")
    //       //       +(credential.kyc_data.fields.address.postal_code ? credential.kyc_data.fields.address.postal_code+", " : "")
    //       //       +(credential.kyc_data.fields.address.postal_code ? "Selected "+credential.kyc_data.fields.address.postal_code : "")
    //       //     this.sss = credential.kyc_data.fields.user_information.socialSecurityNumber ? credential.kyc_data.fields.user_information.socialSecurityNumber : ""
    //       //     this.idclass = credential.kyc_data.fields.user_information.user_identification.identification_class ? credential.kyc_data.fields.user_information.user_identification.identification_class : ""
    //       //     this.idnum = credential.kyc_data.fields.user_information.user_identification.identification_number ? credential.kyc_data.fields.user_information.user_identification.identification_number : ""
    //       //   }
    //       // })
    //     }
    //     else{
    //       this.router.navigate(['/restricted-access'])
    //     }
    //   }
    // })
  }

  // @HostListener('window:beforeunload')
  // canDeactivate(): Observable<boolean> | boolean {
  //   return false
  // }

  // getVerifierById(kycId:any){
  //   // console.log('lvl id',kycId)
  //   this.fS.getDocByRefSnapshot(environment.startC+'/'+kycId)
  //   .subscribe({
  //     next:(result:any)=>{
  //       let credential:any = result.data()
  //       credential.id = result.id
  //       // console.log(credential)
  //       this.inqid = credential.kyc_data.inquiry_id ? credential.kyc_data.inquiry_id : ""
  //       this.name = (credential.kyc_data.fields.user_information.firstName ? credential.kyc_data.fields.user_information.firstName+" " : "") +""+(credential.kyc_data.fields.user_information.middleName ? credential.kyc_data.fields.user_information.middleName+" " : "")+""+(credential.kyc_data.fields.user_information.lastName ? credential.kyc_data.fields.user_information.lastName : "")
  //       this.kyc_fname = credential.kyc_data.fields.user_information.firstName ? credential.kyc_data.fields.user_information.firstName+" " : ""
  //       this.kyc_mname = credential.kyc_data.fields.user_information.middleName ? credential.kyc_data.fields.user_information.middleName+" " : ""
  //       this.kyc_lname = credential.kyc_data.fields.user_information.lastName ? credential.kyc_data.fields.user_information.lastName : ""
  //       this.kyc_email = credential.email ? credential.email : ""
  //       this.phone = credential.kyc_data.fields.user_information.phoneNumber ? credential.kyc_data.fields.user_information.phoneNumber : ""
  //       this.kyc_bday = credential.kyc_data.fields.user_information.birthdate ? credential.kyc_data.fields.user_information.birthdate : ""
  //       this.address = (credential.kyc_data.fields.address.street1 ? credential.kyc_data.fields.address.street1+", " : "")
  //         +(credential.kyc_data.fields.address.street2 ? credential.kyc_data.fields.address.street2+", " : "")
  //         +(credential.kyc_data.fields.address.subdivision ? credential.kyc_data.fields.address.subdivision+", " : "")
  //         +(credential.kyc_data.fields.address.city ? credential.kyc_data.fields.address.city+", " : "")
  //         +(credential.kyc_data.fields.address.country_code ? credential.kyc_data.fields.address.country_code+", " : "")
  //         +(credential.kyc_data.fields.address.postal_code ? credential.kyc_data.fields.address.postal_code+", " : "")
  //         +(credential.kyc_data.fields.address.postal_code ? "Selected "+credential.kyc_data.fields.address.postal_code : "")
  //       this.sss = credential.kyc_data.fields.user_information.socialSecurityNumber ? credential.kyc_data.fields.user_information.socialSecurityNumber : ""
  //       this.idclass = credential.kyc_data.fields.user_information.user_identification.identification_class ? credential.kyc_data.fields.user_information.user_identification.identification_class : ""
  //       this.idnum = credential.kyc_data.fields.user_information.user_identification.identification_number ? credential.kyc_data.fields.user_information.user_identification.identification_number : ""
  //     }
  //   })
  // }

  // goToVerification(){
  //   this.ngZone.run(() => { this.router.navigate(['/persona-verification',this.currentVerifier.id])});
  // }

  goBack(){
    window.location.replace(environment.secureCallback);
  }
}
