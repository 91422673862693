import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationEnd, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable()
export class DisableBack2Guard implements CanActivate{

    // can be bypass by going to another domain ang then using back navigation to go back to this site

    private history:any = []

    constructor(private router: Router){
        this.router.events
        .pipe(filter(event1=> event1 instanceof NavigationEnd))
        .subscribe({
            next:(event)=>{
                // console.log('history', this.history.length)
                // console.log('localStorage previousUrl 1',localStorage.getItem('previousUrl'))
                if(this.history.length == 0 && localStorage.getItem('previousUrl')) this.loadRouting(localStorage.getItem('previousUrl'))
                // if(localStorage.getItem('previousUrl') && !this.history.includes(localStorage.getItem('previousUrl'))) this.loadRouting(localStorage.getItem('previousUrl'))
                // console.log(this.currentUrl+" = "+ event["url"])
                if(this.currentUrl != event["url"]) this.loadRouting(event["url"])
                // if(!this.history.includes(event["url"])) this.loadRouting(event["url"])
            }
        })
    }

    loadRouting(url:string|null){
        this.history.push(url)
        if(this.history.length > 3) this.history.slice(-3)
        localStorage.setItem('previousUrl', this.previousUrl);
        // console.log('localStorage previousUrl 2',localStorage.getItem('previousUrl'))
    }

    get previousUrl(){
        return this.history[this.history.length - 2] || null
    }

    get currentUrl(){
        return this.history[this.history.length - 1] || null
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):boolean{
        // console.log('canActivate previous url: ',this.previousUrl)
        // // const destinationUrl = nextState?.url;
        // // console.log('currentPageId: ',this.router["currentPageId"])
        // // console.log('lastSuccessfulId: ',this.router["lastSuccessfulId"])
        // // console.log('navigationId: ',this.router["navigationId"])
        // // console.log('browserPageId: ',this.router["browserPageId"])
        // console.log('canActivate current url: ',this.router["url"]) 
        // // console.log('router: ',this.router)
        // // console.log('current route: ',route["_routerState"]["url"])
        // // console.log('state: ',state)
        // // console.log('previous: ',this.previousUrl)
        // console.log('canActivatenext route: ',state.url)
        // // if(this.previousUrl && this.previousUrl == state.url) {
        // //     console.log('preventing navigation')
        // //     console.log('-----')
        // //     return false
        // // }
        // // else {
        // //     // this.loadRouting(state.url)
        // //     // this.loadRouting(this.router["url"])
        // //     console.log('proceed to navigation')
        // //     console.log('-----')
        // //     return true
        // // }
        if(this.previousUrl && this.previousUrl == state.url) {
            // console.log('preventing navigation')
            // console.log('-----')
            return false
        }
        else {
            // this.loadRouting(state.url)
            // console.log('proceed to navigation')
            // console.log('-----')
            return true
        }
    }
}