import { Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-persona-verification-payment-modal',
    templateUrl: './persona-verification-payment-modal.component.html',
    styleUrls: ['./persona-verification-payment-modal.component.scss']
})
export class PersonaVerificationPaymentModalComponent implements OnInit {

    urlLink = ""

    constructor(
        public dialogRef: MatDialogRef<PersonaVerificationPaymentModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data:any
    ){}

    ngOnInit(): void {
        // console.log('modal data',this.data)
        if(this.data.urlLink) this.urlLink = this.data.urlLink
        else this.urlLink = ""
    }

    closed(){
        this.dialogRef.close(this.data)
    }
}