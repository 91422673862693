<section class="page-body">
	<section class="show-body">
		<div class="container notice-card">
			<div class="notice-header notice-header-error" style="color:rgb(156, 42, 42)"><h1>Verification Failed</h1></div>
			<!-- <hr> -->
			<div *ngIf="buttonLoading == false && showReverification == true">
				<p>You have failed your verification, click "<span class="text-info fw-bold">Redo Verification</span>" to try again.</p>
				<hr>
			</div>
			<ng-container *ngIf="showErrors && errors">
				<label style="color:rgb(156, 42, 42)"><h3>Causes of Failure</h3></label>
				<ng-container >
					<div *ngIf="errors?.fails.length > 0" style="padding:1em; background-color: white; border-radius: 1em; color:black; margin-bottom: 1em;">
						<div classs="container">
							<div class="row">
								<ng-container *ngFor="let fail of errors.fails">
									<ng-container *ngIf="shownErrors.includes(fail)">
										<div class="col-12 col-lg-6">
											<ng-container [ngSwitch]="fail">
												<ng-container *ngSwitchCase="'id_error'">
													<div class="notice-card">
														<div class="notice-header notice-header-error">
															<h3 class="d-flex align-items-center">
																<mat-icon style="margin-right: 0.3em;">warning</mat-icon> Error in uploaded ID:
															</h3>
														</div>
														<div>
															Pleases ensure of the following:
															<ol>
																<li>The <span class="fw-bold text-success">ID</span> you submitted is <span class="fw-bold text-success">valid and current</span></li>
																<li>The <span class="fw-bold text-success">ID</span> has been <span class="fw-bold  text-success">captured clearly</span> by the camera</li>
															</ol>
					
															We recommend to use the following <span class="fw-bold text-success">IDs</span>:
															<ul>
																<li>PhilSys</li>
																<li>Driver's License</li>
																<li>Passport</li>
																<li>UMID</li>
															</ul>
					
															Important Note:
															<ol>
																<li>UMID ID is <span class="fw-bold text-success">not</span> the same as the SSS ID and vice versa</li>
																<li>Failure to use <span class="fw-bold text-success">two different IDs</span> will result in failure</li>
																<!-- <li>Use two <span class="fw-bold text-success">different IDs</span> or verification will FAIL at the end</li> -->
																<li>We do not advise uploading paper printout of scanned IDs</li>
															</ol>
															Please ensure that the registered details in the eSECURE system math the information of the person conducting the verification and whose ID will be used.
														</div>
													</div>
												</ng-container>
												<ng-container *ngSwitchCase="'same_id'">
													<div class="notice-card">
														<div class="notice-header notice-header-error">
															<h3 class="d-flex align-items-center">
																<mat-icon style="margin-right: 0.3em;">warning</mat-icon> Duplicate ID:
															</h3>
														</div>
														<div>
														You need to provide 2 <span class="fw-bold text-success">DIFFERENT IDs</span>.
														</div>
													</div>
												</ng-container>
												<ng-container *ngSwitchCase="'failed_selfie'">
													<div class="notice-card">
														<div class="notice-header notice-header-error">
															<h3 class="d-flex align-items-center">
																<mat-icon style="margin-right: 0.3em;">warning</mat-icon> Liveness/Face Verification failed:
															</h3>
														</div>
														<div>
															Please ensure of the following:
															<ol>
																<li>The person doing the liveness check is the <span class="fw-bold text-success">same person on the ID</span></li>
																<li>You take your selfie in a <span class="fw-bold text-success">well-lit environment</span> using a <span class="fw-bold text-success">sufficiently clear camera</span></li>
																<li>Ensure that your <span class="fw-bold text-success">entire face is visible</span> during the liveness check <span class="fw-bold text-success">without any obstruction</span>, such as hair or accessories covering significant features like the eyes, nose, and lips. </li>
																<!-- <li>You need to ensure that the <span class="fw-bold text-success">full face is seen</span> while doing liveness check. This means the <span class="fw-bold text-success">hair and other accessories must not be covering major features</span> of the face like the eyes, nose, and lips</li> -->
															</ol>
														</div>
													</div>
												</ng-container>
												<!-- <ng-container *ngSwitchCase="'no_liveness_check'">
													<div class="notice-card">
														<div class="notice-header notice-header-error">
															<h3 class="d-flex align-items-center">
																<mat-icon style="margin-right: 0.3em;">warning</mat-icon> Liveness/Face Verification Missing:
															</h3>
														</div>
													</div>
												</ng-container> -->
												<ng-container *ngSwitchCase="'name_mismatch'">
													<div class="notice-card">
														<div class="notice-header notice-header-error">
															<h3 class="d-flex align-items-center">
																<mat-icon style="margin-right: 0.3em;">warning</mat-icon> First Name / Last Name mismatch:
															</h3>
														</div>
														<div>
															Please check that the <span class="fw-bold text-success">NAME</span> you have registered in the eSECURE system is the same <span class="fw-bold text-success">NAME</span> on your submitted ID.
														</div>
													</div>
												</ng-container>
												<ng-container *ngSwitchCase="'birthday_mismatch'">
													<div class="notice-card">
														<div class="notice-header notice-header-error">
															<h3 class="d-flex align-items-center">
																<mat-icon style="margin-right: 0.3em;">warning</mat-icon> Birthdate mismatch:
															</h3>
														</div>
														<div>
															Please check that the <span class="fw-bold text-success">BIRTHDATE</span> you have registered in the eSECURE system is the same <span class="fw-bold text-success">BIRTHDATE</span> on your submitted ID.
														</div>
													</div>
												</ng-container>
											</ng-container>
										</div>
									</ng-container>
								</ng-container>
							</div>
		
							If you have any further issues, please contact <span class="fw-bold">support&#64;cyantech.io</span>.
						</div>
					</div>
					<!-- <div *ngFor="let level1nested of errors.nested" style="padding:1em; background-color: white; border-radius: 1em; color:white; margin-bottom: 1em; background-color: rgb(156, 42, 42);">
						<h4>{{level1nested.id}}</h4>
						<hr>
						<ul>
							<li *ngFor="let level2fail of level1nested.fails">{{level2fail}}</li>
							<li *ngFor="let level2nested of level1nested.nested">
								{{level2nested.id}}
								<ng-container *ngIf="level2nested.reason">
									<ul style="list-style-type: circle; padding-left:2em">
										<li *ngFor="let level2reason of level2nested.reason">
											{{level2reason}}
										</li>
									</ul>
								</ng-container>
							</li>
						</ul>
					</div> -->
				</ng-container>
				<hr>
			</ng-container>
			<ng-container *ngIf="buttonLoading == false">
				<div *ngIf="showReverification == true; else noChance">
					<ng-container *ngIf="accessCount >= 0;">
						<h4>
							<p>Remaining Retries: <span class="fw-bold" style="color:rgb(156, 42, 42)">{{accessCount}}</span></p>
						</h4>
					</ng-container>
					<ng-container *ngIf="accessCount == 1;">
						<p class="fw-bold fst-italic">Note*: This is your last chance to verify yourself correctly</p>
					</ng-container>
				</div>
				<ng-template #noChance>
					<div>
						<p>You have failed your verification.</p>
					</div>
				</ng-template>
				<hr>
			</ng-container>
			<div class="d-flex justify-content-between">
				<ng-container *ngIf="buttonLoading; else buttonsLoaded">
						<div class="container">
								<div class="d-flex justify-content-center align-items-center">
										<img src="assets/loaders/loading.svg">
								</div>
						</div>
				</ng-container>
				<ng-template #buttonsLoaded>
					<button *ngIf="showReverification" (click)="reverifyKYC()" type="button"
						class="btn d-flex btn-info white-text justify-content-center align-items-center shadow " >
						<span >Redo Verification</span>
					</button>
					<!-- <button class="btn btn-success" (click)="goBack()">
						Back to SEC Philippines
					</button> -->
				</ng-template>
			</div>
		</div>
	</section>
</section>