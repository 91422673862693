<section class="page-body">
    <section class="show-body">
        <div class="paymongo_image">
            <div class="img-wrapper">
                <img src="assets/images/paymongo/Paymongo_Final_Main_Logo_2020_RGB_green_horizontal_resized.png">
            </div>
        </div>
        <div style="margin-top: 2em;">
            <div class="container">
                <div class="row">
                    <div class="col d-flex align-items-center flex-wrap">
                        <span class="fw-bold" >Amount</span>
                        <input class="form-control" readonly disabled type="text" placeholder="" [value]="verifier.valueToBePaid">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg" style="margin-top: 1em;">
                        <span class="fw-bold">First name</span>
                        <input class="form-control" readonly disabled type="text" [ngModel]="verifier.fname" placeholder="">
                    </div>
                    <div class="col-12 col-lg" style="margin-top: 1em;">
                        <span class="fw-bold">Last name</span>
                        <input class="form-control" readonly disabled type="text" [ngModel]="verifier.lname" placeholder="">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg" style="margin-top: 1em;" [formGroup]="createTransactionForm">
                        <span class="fw-bold">Mobile number</span>
                        <div class="d-flex align-items-center">
                            <input class="form-control static-input-width" readonly disabled type="text" value="+63">
                            <input class="form-control" id="contactNo" name="contactNo" appPhoneMask maxlength="12" type="text" formControlName="contactNo" placeholder="Ex. 912-345-678">
                        </div>
                        <ng-container>
                            <span style="color:rgb(45, 108, 233)" class="fw-bold" *ngIf="!isTouched('contactNo')">Input Optional</span>
                        </ng-container>
                        <ng-container *ngFor="let item of createTransactionFormCheck.contactNo">
                            <span style="color:rgb(201, 38, 38); font-weight: bold;" *ngIf="hasError('contactNo',item.type)">* {{item.message}}</span>
                        </ng-container>
                    </div>
                    <div class="col-12 col-lg" style="margin-top: 1em;">
                        <span class="fw-bold">Email</span>
                        <div class="d-flex align-items-center"><input class="form-control" readonly disabled type="text" [ngModel]="verifier.email" placeholder=""></div> 
                    </div>
                </div>
            </div>
        </div>
        
        <div class="container d-flex justify-content-center" style="margin-top: 2em;">
            <button type="button" (click)="createTransaction()" [disabled]="proceedToPayMongoLoading" class="btn btn-primary w-100">
                <span [hidden]="!proceedToPayMongoLoading">
                    <img style="margin-right: 1em; width: 2rem; height: 2rem;" src="assets/loaders/loading.svg">
                </span>
                <span>Proceed to Payment</span>
            </button>
        </div>
    </section>
</section>
