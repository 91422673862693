<h2 mat-dialog-title ><div style="padding: 0 2em;">Powered by PayMongo</div></h2>
<mat-dialog-content class="mat-typography d-flex justify-content-center align-items-center" style="height: 85%; padding: 0; margin: 0;">
    <ng-container *ngIf="urlLink; else noLink">
        <iframe [src]="urlLink | safe" style="width: 100%;height: 100%;" frameborder="0">
            
        </iframe>
        <!-- {{urlLink}} -->
    </ng-container>
    <ng-template #noLink>
        invalid
    </ng-template>
</mat-dialog-content>
<!--  align="end" -->
<div>
<mat-dialog-actions class="d-flex flex-row justify-content-between" style="padding: 0 2em;">
    <div style="padding-bottom:1em;"  class="d-flex align-items-center"><i>Close after successful payment or to cancel transaction</i></div>
    <div align="end" class="d-flex align-items-center">
        <button type="button" class="btn btn-danger" (click)="closed()">Close</button>
    </div>
    <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
</mat-dialog-actions>
</div>