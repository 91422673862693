import { Component, OnInit, NgZone, HostListener, Input, Output, EventEmitter} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { APICallsService } from 'src/app/core/services/APICalls.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonaVerificationPaymentModalComponent } from '../persona-verification-payment-modal/persona-verification-payment-modal.component';

@Component({
  selector: 'app-create-transaction',
  templateUrl: './create-transaction.component.html',
  styleUrls: ['./create-transaction.component.scss']
})
export class CreateTransactionComponent implements OnInit{
    
  @Input() verifier:any;
  @Input() id:any;
  @Output() triggerPedingTransaction = new EventEmitter<string>();
  @Output() triggerRecheckVerifier = new EventEmitter<string>();

  proceedToGoodPayLoading:boolean = false;
  valueToBePaid:number = environment.paymentAmmout;

  createTransactionForm = new FormGroup({
    contactNo: new FormControl('',{validators:[
      // Validators.required,
      Validators.minLength(12),
      Validators.pattern(/(\d{0,9})/g),
    ]})
  })
    
  createTransactionFormCheck = {
    contactNo: [
      {type: 'required', message: 'Phone No. is required for payment'},
      // {type: 'pattern', message: 'Phone No. must contain numbers only'},
      {type: 'minlength', message: 'Phone No. must contain atleast 10 digits.'},
      // {type: 'maxlength', message: 'Phone No. must contain atmost 10 digits.'},
    ]
  }
      
  constructor(
    // private route: ActivatedRoute,
    // private fS: FirebaseService,
    private router: Router,
    private ngZone: NgZone,
    // private http: HttpClient,
    public dialog: MatDialog,
    private apiS: APICallsService,
    ) 
  { }

  ngOnInit(): void {
      
  }

  openGoodPayModal(urlLink?:any){
    // get link from 'https://goodpay.ph/'
    // if(!urlLink) urlLink = 'https://google.com.ph/'

    let data = {
      urlLink: urlLink
    }

    const modalOptions = {
      disableClose: true,
      height: '90vh',
      width: '90vw',
      panelClass: 'custom-dialog-class',
      data: data
    }

    const dialogRef = this.dialog.open(PersonaVerificationPaymentModalComponent,modalOptions);

    dialogRef.afterClosed().subscribe({
      next:(dialogReturn:any)=>{
        // console.log('next',dialogReturn)
        // this.checkGoodPayTransaction()
        this.triggerRecheckVerifier.emit()
      },
      error:(err:any)=>{
        // console.log('error',err)
        // console.error('Error')
        // this.checkGoodPayTransaction()
        this.triggerRecheckVerifier.emit()
      }
    })
  }

  createTransaction(){
    // create payment transaction
    this.validateFields()
    this.proceedToGoodPayLoading = true;
    if(this.createTransactionForm.valid){
      // go to server with id and contact number
      // create transaction
      const createTransactionVal = this.createTransactionForm.value
      let contact_num = createTransactionVal.contactNo.replace(/[^0-9]/g,'')
      
      const verifierData = {
        id: this.id,
        contactNum: contact_num,
      }

      this.apiS.apiPost(this.apiS.getCreateGoodPayTransactionUrl,verifierData)
      .subscribe({
        next:(apiResult)=>{
          if(apiResult){
            // console.log('createTransaction: ',apiResult)
            if(apiResult.success === true){
              if(apiResult.data){
                const goodpayData = apiResult.data
                if(goodpayData){
                  if(goodpayData.forPayment === true){
                    if(goodpayData.link){
                      // this.openGoodPayModal(goodpayData.link)
                      window.location.replace(goodpayData.link)
                    }
                    else {
                      // no link contact help
                      this.proceedToGoodPayLoading = false;
                      this.ngZone.run(()=>this.router.navigate(['/page-error']))
                    }
                  }
                  else if(goodpayData.forPedingTransaction === true){
                    if(goodpayData.goodpayTransactionLink){
                      // emit goodpayData back to parent
                      this.triggerPedingTransaction.emit(goodpayData)
                    }
                    else{
                      // no transaction link contact help
                      this.proceedToGoodPayLoading = false;
                      this.ngZone.run(()=>this.router.navigate(['/page-error']))
                    }
                  }
                }else{
                  // no result.data contact help
                  this.proceedToGoodPayLoading = false;
                  this.ngZone.run(()=>this.router.navigate(['/page-error']))
                }
              }
              else{
                // no verifier data
                this.proceedToGoodPayLoading = false;
                this.ngZone.run(()=>this.router.navigate(['/page-error']))
              }
            }else{
              // result success false contact help
              this.proceedToGoodPayLoading = false;
              this.ngZone.run(()=>this.router.navigate(['/page-error']))
            }
          }else{
            this.proceedToGoodPayLoading = false;
            // no result from server contact help
            this.ngZone.run(()=>this.router.navigate(['/page-error']))
          }
        },
        error:(err)=>{
          // error occured within the server that doesnt have an error handler server / returned bad request
          // console.error('Error: ',err)
          // console.error('Error')
          this.proceedToGoodPayLoading = false;
          this.ngZone.run(()=>this.router.navigate(['/page-error']))
        }
      })
    }
    else{
      this.proceedToGoodPayLoading = false;
      // console.error('Invalid forms')
      this.ngZone.run(()=>this.router.navigate(['/restricted-access']))
    }
  }

  validateFields(): void {
    if (!this.createTransactionForm.valid) {
      // Mark the form and inputs as touched so the errors messages are shown
      this.createTransactionForm.markAsTouched();
      for (const control in this.createTransactionForm.controls) {
        if (this.createTransactionForm.controls.hasOwnProperty(control)) {
          this.createTransactionForm.controls[control].markAllAsTouched();
          this.createTransactionForm.controls[control].markAsDirty();
        }
      }
    }
  }

  isTouched(controlName:any):boolean{
    return (this.createTransactionForm.get(controlName) as FormControl).touched;
  }

  hasError(controlName:any, validType:any){
    return ((this.createTransactionForm.get(controlName) as FormControl).hasError(validType) 
    && ((this.createTransactionForm.get(controlName) as FormControl).dirty 
    || (this.createTransactionForm.get(controlName) as FormControl).touched));
  }
}