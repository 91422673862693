<ng-container [ngSwitch]="showView">
  <!-- recredential-transaction  -->
  <section  *ngSwitchCase="'recredential-transaction'">
    <app-recredential-transaction [verifier]="verifier" [verifierId]="verifierId" [recredentialData]="recredentialData" [transactionId]="transactionId"
    (triggerPedingTransaction)="triggerPedingTransaction($event)"
    (triggerRecheckVerifier)="triggerRecheckVerifier()">
    </app-recredential-transaction>
  </section>

  <!-- no recredential data -->
  <section  *ngSwitchCase="'recredential-updated'">
    <app-recredential-updated [verifier]="verifier" [verifierId]="verifierId">
    </app-recredential-updated>
  </section>

  <!-- already approved -->
  <section  *ngSwitchCase="'already-approved'">
    <app-recredential-updated [verifier]="verifier" [verifierId]="verifierId">
    </app-recredential-updated>
  </section>

  <!-- already approved -->
  <section  *ngSwitchCase="'no-recredential'">
    <section>
      <div class="container">
        <div class="d-flex justify-content-center">
          <div class="form-group card shadow notice-card">
            <div class="notice-loader card d-flex justify-content-center align-items-center flex-wrap flex-row">
              <div class="loader-size d-flex justify-content-center align-items-center">
                <mat-icon class="loader-icon green-color">close</mat-icon>
                <span class="no-recredential-text" >No Recredential Update</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>

  <!-- loading -->
  <section *ngSwitchCase="'loading'">
    <div class="container container-loader">
        <div class="d-flex justify-content-center align-items-center flex-wrap">
            <img src="assets/loaders/loading.svg">
            <span class="text">Loading</span>
        </div>
    </div>
  </section>
</ng-container>