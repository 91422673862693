// import { FailedVerificationComponent } from './failed-verification/failed-verification.component';
// import { SuccessVerificationComponent } from './success-verification/success-verification.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../core/core.module';
import { SharedModule } from './../shared/shared.module';

//forms
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

//
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

//declarations
import { ParameterVerificationComponent } from './paremeter-verification/paremeter-verification.component';
import { PersonaVerificationComponent } from './persona-verification/persona-verification.component';
import { SuccessVerificationComponent } from './success-verification/success-verification.component';
import { RestrictedAccessComponent } from './restricted-access/restricted-access.component';
import { FailedVerificationComponent } from './failed-verification/failed-verification.component';
import { CreateTransactionComponent } from './persona-verification/create-transaction/create-transaction.component';
import { PendingTransactionComponent } from './persona-verification/pending-transaction/pending-transaction.component';
import { VerifyUserComponent } from './persona-verification/verify-user/verify-user.component';
import { UnderMaintenanceComponent } from './under-maintenance/under-maintenance.component';
// import { TryComponent } from './try/try.component';
// materials
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';

import { HttpClientModule } from '@angular/common/http';

import { PhoneMaskDirective } from '../shared/mask/phone-mask.directive';
import { PersonaVerificationPaymentModalComponent } from './persona-verification/persona-verification-payment-modal/persona-verification-payment-modal.component';
import { SafePipe } from '../shared/pipes/safepipe';

const FormModules = [
    FormsModule,
    ReactiveFormsModule,
]

const Materials = [
    MatIconModule,
    MatDialogModule,
    MatExpansionModule,
]

const PipeModules = [
    SafePipe
]

@NgModule({
    declarations: [
        ParameterVerificationComponent,
        PersonaVerificationComponent,
        SuccessVerificationComponent,
        FailedVerificationComponent,
        RestrictedAccessComponent,
        PersonaVerificationPaymentModalComponent,
        CreateTransactionComponent,
        PendingTransactionComponent,
        VerifyUserComponent,
        UnderMaintenanceComponent,
        // TryComponent,
        PipeModules,
        PhoneMaskDirective,
    ],
    imports:[
        CoreModule,
        SharedModule,

        CommonModule,
        RouterModule,
        FormModules,
        Materials,
        BrowserAnimationsModule,

        HttpClientModule,
    ],
    exports: [

    ],
    providers: [

    ],
})
export class ModulesModule {}