import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
  AuthGuard,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
  hasCustomClaim
} from '@angular/fire/auth-guard';

import { ParameterVerificationComponent } from './modules/paremeter-verification/paremeter-verification.component';
import { PersonaVerificationComponent } from './modules/persona-verification/persona-verification.component';
import { SuccessVerificationComponent } from './modules/success-verification/success-verification.component';
import { RestrictedAccessComponent } from './modules/restricted-access/restricted-access.component';
import { FailedVerificationComponent } from './modules/failed-verification/failed-verification.component';
import { DisableBackGuard } from './shared/gaurd/disable-back.gaurd';
import { TempForwardGuard } from './shared/gaurd/temp-forward.gaurd';
import { DisableBack2Guard } from './shared/gaurd/disable-back-2.gaurd';
import { ErrorOccuredComponent } from './modules/error-occured/error-occured.component';
import { UnderMaintenanceComponent } from './modules/under-maintenance/under-maintenance.component';
// import { TryComponent } from './modules/try/try.component';

const redirectedToRestrictedAccess = () => redirectUnauthorizedTo(["/restricted-access"])

const routes: Routes = [
  {
    path: '',
    redirectTo:'verifying',
    pathMatch:'full',
    // canActivate: [TempForwardGuard],
    // canDeactivate: [DisableBackGuard]
  },
  // {
  //   path: 'verifying',
  //   component: ParameterVerificationComponent,
  //   data:{ title: 'Verifying'}
  // },
  {
    path: 'verifying/:tokenId',
    component: ParameterVerificationComponent,
    data:{ title: 'Verifying'},
    // canActivate: [DisableBack2Guard],
    // canDeactivate: [DisableBackGuard]
  },
  {
    // path: 'persona-verification/:tokenId',
    path: 'persona-verification',
    component: PersonaVerificationComponent,
    data:{ title: 'KYC Verifying'},
    // canActivate: [DisableBack2Guard],
    // canDeactivate: [DisableBackGuard]
  },
  {
    // path: 'verification-success/:id',
    path: 'verification-success',
    component: SuccessVerificationComponent,
    data:{ title: 'Successful Verification'},
    // canActivate: [DisableBack2Guard],
    // canDeactivate: [DisableBackGuard]
  },
  {
    // path:'verification-failed/:id',
    path:'verification-failed',
    component: FailedVerificationComponent,
    data:{ title: 'Failed Verification'},
    // canActivate: [DisableBack2Guard],
    // canDeactivate: [DisableBackGuard]
  },
  {
    // path:'page-error/:id',
    path:'page-error',
    component: ErrorOccuredComponent,
    data:{ title: 'Error'},
    canActivate: [DisableBack2Guard],
    // canDeactivate: [DisableBackGuard]
  },
  {
    path:'under-maintenance',
    component: UnderMaintenanceComponent,
    data:{ title: 'Under Maintenance'},
    // canActivate: [DisableBack2Guard],
    // canDeactivate: [DisableBackGuard]
  },
  {
    path:'restricted-access',
    component: RestrictedAccessComponent,
    data:{ title: 'Restricted Access'},
    // canActivate: [DisableBack2Guard],
    // canDeactivate: [DisableBackGuard]
  },
  { 
    path:'**', 
    redirectTo:'restricted-access',
    pathMatch:'full',
    // canActivate: [DisableBack2Guard],
    // canDeactivate: [DisableBackGuard]
  },
];

@NgModule({
  imports:[
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
