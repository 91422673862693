<section>
    <div class="container" style="height: 100vh">
        <div style="height: 100vh;" class="d-flex justify-content-center align-items-center">
            <!-- style="width: 50em;background-color: rgb(216, 105, 90); padding:2em; color:whitesmoke" -->
            <div class="form-group card shadow notice-card" >
                <div class="notice-header notice-header-warning">
                    <h2 class="d-flex align-items-center fw-bold">
                        Something went wrong!
                    </h2>
                </div>
                Please contact support
                <!-- <hr>
                <div class="d-flex justify-content-between">
                    <button class="btn btn-success" (click)="goBackToSec()">
                        Back to SEC Philippines
                    </button>
                </div> -->
            </div>
        </div>
    </div>
</section>