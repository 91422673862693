<section class="page-body">
  <section class="show-body">
      <div class="paymongo_image">
          <div class="img-wrapper">
              <img src="assets/images/paymongo/Paymongo_Final_Main_Logo_2020_RGB_green_horizontal_resized.png">
          </div>
      </div>
      <!-- class="d-flex justify-content-center align-items-center" -->
      <div style="margin-top: 2em;">
          <div class="container">
            <div class="row">
              <div class="col-12 col-lg" style="margin-top: 1em;">
                <span class="fw-bold">Email</span>
                <div class="d-flex align-items-center"><input class="form-control" readonly disabled type="text" [ngModel]="verifier?.email" placeholder=""></div> 
              </div>
            </div>
            <hr>
            <div class="row p-1">
              <div class="col-12 col-lg card m-1 p-1" style="margin-top: 1em;">
                <div class="row">
                  <h4>Updated User Details:</h4>
                </div>
                <div class="row">
                  <div class="col-12 col-lg" style="margin-top: 1em;">
                      <span class="fw-bold">First name</span>
                      <input class="form-control" readonly disabled type="text" [ngModel]="verifier?.fname" placeholder="">
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg" style="margin-top: 1em;">
                      <span class="fw-bold">Middle name</span>
                      <input class="form-control" readonly disabled type="text" [ngModel]="verifier?.mname" placeholder="">
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg" style="margin-top: 1em;">
                      <span class="fw-bold">Last name</span>
                      <input class="form-control" readonly disabled type="text" [ngModel]="verifier?.lname" placeholder="">
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg" style="margin-top: 1em;">
                      <span class="fw-bold">Birthday</span>
                      <input class="form-control" readonly disabled type="text" [ngModel]="verifier?.stringFormatBd" placeholder="">
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      
      <div class="container d-flex justify-content-center" style="margin-top: 2em;">
          <button type="button" (click)="proceedToVerification()" class="btn btn-primary w-100">
            Continue
          </button>
      </div>
  </section>
</section>