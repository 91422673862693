// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environmentURLCode:'/dev',
  firebaseConfig: {
    apiKey: "AIzaSyDFa9Sg7pucNUc-aH9NXc0FVRp5_fM99Xg",
    authDomain: "sec-keychain.firebaseapp.com",
    projectId: "sec-keychain",
    storageBucket: "sec-keychain.appspot.com",
    messagingSenderId: "269125428675",
    appId: "1:269125428675:web:ccbbdc348a0544e8bd8976",
    measurementId: "G-8X54VJ7Y9P"
  },
  proxyCreateAPIUrl: 'https://us-central1-sec-keychain.cloudfunctions.net/goodpayProxyApi/api/v1/proxyPay', //change
  proxyFetchAPIUrl:'https://us-central1-sec-keychain.cloudfunctions.net/goodpayProxyApi/api/v1/proxyGetTransaction/', //change
  verificationC:'verification-collection-dev',
  paymentTC:'Payment-Transaction-dev',
  startC:'Start-Credentialing-dev',
  dailyTC:'Daily-Transaction',
  paymentAmmout:10,
  secureCallback:'https://dev-esecure.sec.gov.ph',//https://sandbox.sec.gov.ph:8040/app/credentialing/callback
  secServerBaseUrl: 'https://us-central1-sec-keychain.cloudfunctions.net/SECServer',
  secServerWithIPBaseUrl: 'https://us-central1-sec-keychain.cloudfunctions.net/SECServerWithStaticIP',
  skip:{
    // goodPay:{
    //   payment:false,
    // },
    verification:{
      allowedOnWeekendCheck:false,
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
