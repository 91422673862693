<section class="page-body">
    <section class="show-body">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg form-group card shadow notice-card">
                    <div class="notice-header notice-header-good">
                        <h4 class="d-flex align-items-center">
                            <mat-icon> format_list_numbered</mat-icon>eKYC Requirements
                        </h4>
                    </div>
                    <div>
                        <ol>
                            <li>You need to upload 2 different IDs for the Verification.</li>
                            <li>You need to remove eyeglasses while doing Liveness Check.</li>
                            <li>You need to ensure that the full face is seen while doing liveness check. This means the hair and other accessories must not be covering major features of the face like the eyes, nose, and lips.</li>
                        </ol>
                    </div>
                </div>
                <div class="col-12 col-lg form-group card shadow notice-card">
                    <div class="notice-header notice-header-good">
                        <h4 class="d-flex align-items-center">
                            <mat-icon>note</mat-icon>Note
                        </h4>
                    </div>
                    <div>
                        <ol>
                            <li>UMID ID is not SSS ID and vice versa</li>
                            <li>Use two different IDs or verification will FAIL at the end</li>
                            <li>We do not advise using scanned paper PhilSys ID</li>
                            <li>We do not advise uploading paper printout of scanned IDs Please ensure that the registered details are information of the person transacting and whose ID will be used</li>
                        </ol>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg form-group card shadow notice-card">
                    <div class="notice-header notice-header-good">
                        <h4 class="d-flex align-items-center">
                            <mat-icon>perm_identity</mat-icon>Accepted IDs
                        </h4>
                    </div>
                    <div>
                        <ul>
                            <li>Passport</li>
                            <li>Driver's License</li>
                            <li>Philsys ID</li>
                            <li>PhilHealth ID</li>
                            <li>OFW ID</li>
                            <li>Postal ID</li>
                            <li>SSS ID</li>
                            <li>UMID ID</li>
                            <li>Voter's ID</li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-lg form-group card shadow notice-card">
                    <!-- class="d-flex align-items-center" -->
                    <div class="notice-header notice-header-error">
                        <h4 class="d-flex align-items-center">
                            <mat-icon>error</mat-icon>Credential Failures
                        </h4>
                    </div>
                    <div>
                        <ul>
                            <li>Birthdays do not match between provided ID and SEC eSECURE Portal Registration</li>
                            <li>Names do not match between provided ID and SEC eSECURE Portal Registration</li>
                            <li>ID Photo does not resemble the applicant</li>
                            <li>Difference in pictures and appearance of person doing KYC is significant (Either due to Aging, Plastic Surgery, Worn Accessories like glasses and other face coverings)</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg form-group card shadow notice-card">
                    <div class="notice-header notice-header-good">
                        <h4 class="d-flex align-items-center">
                            <mat-icon>perm_identity</mat-icon>ACCEPTED PHILHEALTH IDs
                        </h4>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <span style="margin-bottom: 2em;">For PHIC ID submission, please see below the accepted versions:</span>
                                <div class="row" >
                                    <div class="col-12 col-lg" style="margin-bottom: 1em;">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <img class="notice-img" src="assets/images/sec/accepted_philhealth_2.jpeg">
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg" style="margin-bottom: 1em;">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <img class="notice-img" src="assets/images/sec/accepted_philhealth_1.jpg" >
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="d-flex justify-content-evenly" style="margin-bottom: 1em;">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <img class="notice-img" src="assets/images/sec/accepted_philhealth_2.jpeg">
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center">
                                        <img class="notice-img" src="assets/images/sec/accepted_philhealth_1.jpg" >
                                    </div>
                                </div> -->
                                <span style="color:red" class="fw-bold">Note: Other versions do not contain sufficient information for verification</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- <div class="col-12 col-lg form-group notice-card">

                </div> -->
            </div>
            <div class="row">
                <div class="col-12 col-lg d-flex align-items-center flex-wrap" style="margin-top: 1em;">
                    <span class="fw-bold">First Name</span>
                    <input class="form-control" readonly disabled type="text" [ngModel]="verifier.fname" placeholder="Ex. John">
                </div>
                <div class="col-12 col-lg d-flex align-items-center flex-wrap" style="margin-top: 1em;">
                    <span class="fw-bold">Middle Name</span>
                    <input class="form-control" readonly disabled type="text" [ngModel]="verifier.mname" placeholder="Ex. M.">
                </div>
                <div class="col-12 col-lg d-flex align-items-center flex-wrap" style="margin-top: 1em;">
                    <span class="fw-bold">Last Name</span>
                    <input class="form-control" readonly disabled type="text" [ngModel]="verifier.lname" placeholder="Ex. Doe">
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg d-flex align-items-center flex-wrap" style="margin-top: 1em;">
                    <span class="fw-bold">Email</span>
                    <input class="form-control" readonly disabled type="text" [ngModel]="verifier.email" placeholder="Ex. sec_verification@email.com">
                </div>
                <div class="col-12 col-lg d-flex align-items-center flex-wrap" style="margin-top: 1em;">
                    <span class="fw-bold">Birthday</span>
                    <input class="form-control" readonly disabled type="text" [ngModel]="verifier.bd" placeholder="Ex. Month Day, Year">
                </div>
            </div>
            <ng-container *ngIf="transactionCheck.internalError && personaLoader">
                <div class="d-flex justify-content-center" style="font-weight: bold; color:rgb(255, 57, 57); margin-top: 1em;">
                    Internal Error.
                </div>
            </ng-container>
            <div class="row">
                <div class="col-12 col-lg" style="margin-top: 1em;">
                    <button (click)="verifyPersona()" 
                        type="button"
                        [disabled]="!personaLoader"
                        class="btn btn-info
                            white-text full-width 
                            d-flex shadow
                            justify-content-center
                            align-items-center" >
                        <span [hidden]="personaLoader">
                            <img style="margin-right: 1em; width: 2rem; height: 2rem;" src="assets/loaders/loading.svg">
                        </span>
                        <span >Begin Verification</span>
                    </button>
                </div>
                <!-- <div class="col-12 col-lg" style="margin-top: 1em;">
                    <button class="btn btn-success full-width d-flex shadow justify-content-center align-items-center" (click)="goBackToSec()">
                        Back to SEC Philippines
                    </button>
                </div> -->
            </div>
        </div>
    </section>
</section>