import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { ComponentCanDeactivate } from 'src/app/shared/gaurd/disable-back.gaurd';
// import { Observable } from 'rxjs';

@Component({
  selector: 'app-restricted-access',
  templateUrl: './restricted-access.component.html',
  styleUrls: ['./restricted-access.component.scss']
})
export class RestrictedAccessComponent implements OnInit { // , ComponentCanDeactivate 

  hasErrors: boolean = false
  errorsData: any[] = []
  
  constructor(
    private route: ActivatedRoute,
  ) 
  {}

  ngOnInit(): void {
    this.checkErrors()
  }

  // @HostListener('window:beforeunload')
  // canDeactivate(): Observable<boolean> | boolean {
  //   return false
  // }

  checkErrors(){
    this.route.queryParams.subscribe({
      next:(params:any) => {
        let passedData:any = params
        if(passedData.errors != undefined){
          this.hasErrors = true
          // this.errorsData = passedData.errors
          let isArray = Array.isArray(passedData.errors)
          if(isArray == true){
            this.errorsData = passedData.errors
          }else{
            this.errorsData.push(passedData.errors)
          }
        }
      },
      error:(err:any)=>{
        // console.log("Error: ",err)
        // console.error('Error')
      }
    })
  }
}
