import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-error-occured',
    templateUrl: './error-occured.component.html',
    styleUrls: ['./error-occured.component.scss']
  })
  export class ErrorOccuredComponent implements OnInit{
    

    constructor(
        ){
    }

    ngOnInit(): void {
        
    }
  
    goBackToSec(){
      window.location.replace(environment.secureCallback);
    }
  }