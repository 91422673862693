<section>
    <div class="container" style="height: 100vh;">
        <div style="height: 100vh;" class="relative-card d-flex justify-content-center align-items-center">
            <div class="d-flex align-items-center flex-column">
                <div class="form-group success-card card shadow" style="background-color: rgb(90, 216, 164); padding:2em; color:whitesmoke">
                    <div style="color:rgb(14, 97, 197)"><h1>Verification Completed</h1></div>
                    <!-- <hr>
                    <div class="d-flex justify-content-between">
                                                        <ng-container *ngIf="currentVerifier">
                                                            <button style="color: whitesmoke;" *ngIf="currentVerifier.kyc_data.kyc_lvl == 1" class="btn btn-info" (click)="goToVerification()">
                                                                Begin KYC Verification 2
                                                            </button>
                                                        </ng-container>
    
                        <button class="btn btn-success" (click)="goBack()">
                            Back to SEC Philippines
                        </button>
                    </div> -->
                </div>
            </div>
            
            <div class="absolute-card form-group notice-card shadow d-flex flex-row" style="margin-bottom: 3em; background-color: aquamarine; padding: 0.5em; border: 1pt solid;">
                <div>
                    <img class="notice-img" style="margin-right: 1em;" src="assets/images/icons/exclamation_1.png">
                </div>
                <div class="d-flex align-items-center" >
                    <span>Kindly click the "<b style="color: red;">Close</b>" button to finish the transaction and return to SEC Philippines></span>
                </div>
            </div>
        </div>
    </div>
</section>