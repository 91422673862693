import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

//Angular
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';

//forms
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

// Global Components
import { FooterComponent } from './layout/footer/footer.component';
import { NavbarComponent } from './layout/navbar/navbar.component';

//Providers

const AngularModules = [
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
]

const FormModules = [
    FormsModule,
    ReactiveFormsModule,
]

const GlobalComponents = [
    NavbarComponent,
    FooterComponent,
]

@NgModule({
    imports:[
        CommonModule,
        RouterModule,
        FormModules,
        AngularModules,
    ],
    declarations: [
        GlobalComponents,
    ],
    exports: [
        GlobalComponents,
    ],
    providers: [
        GlobalComponents,
    ],
})
export class CoreModule {}